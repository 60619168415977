import React from "react";
import Icon1 from "../../assets/icons/iq1.png";
import Icon2 from "../../assets/icons/iq2.png";
import Icon3 from "../../assets/icons/iq3.png";
import "./IQPlatform.scss";
import ImageSideIQComponent from "../../components/ImageSideIQComponent/ImageSideIQComponent";
import logo from "../../assets/ab1-small-icon.png";
import IQLaptop from "../../assets/IQLaptop.png";
import AB1_Investor_Slide from  "../../assets/AB1_Investor_Slide.png";
import AB1_Investor_Slide_Mobile from  "../../assets/AB1_Investor_Slide_mobile.png";
import aircraft_img from  "../../assets/aircraft-img.png";
const IQPlatform = () => {
  let statements = [
    {
      icon: Icon1,
      statement: "Highly Accurate Aircraft Availability Data",
    },
    {
      icon: Icon2,
      statement: "Elegant Booking & Booking Management Platforms",
    },
    {
      icon: Icon3,
      statement: "Real Time Market Analytic Reporting",
    },
  ];

  return (
    <div className="iq-main iq">
      <div className="bg-img">
        <div className="iq-demo">
          <div className="iq-root-mission">
          <img className="icon" src={logo} alt="" />
            <h1 className="iq-mission">
              Mission sourcing and booking. SIMPLIFIED.
            </h1>
            <div style={{ fontSize:'18px', fontWeight:'600' }}>
              AIRBOOKONE’s IQ Platform is a White Label Solution to make your
              Aviation Technology Seamless.
            </div>
          </div>
          <div className="iq-setup-mission">
            <a href="https://meetings.hubspot.com/sales-ab1" target="_blank">
              <button className="iq-setup-demo">Setup a Demo</button>
            </a>
          </div>
        </div>
        <img className="icon laptop-img" style={{ marginTop: '80px' , marginRight: '50px'  }} src={IQLaptop} alt="" />
      </div>
      <div>
      <div className="how-we-build">
      <img className="icon AB1-Investor-Slide" src={AB1_Investor_Slide} alt="" />
      </div>
      <div className="how-we-build-mobile" style={{ display:'none'}}>
      <h3>How We <br/> Are Built</h3>
      <img className="icon AB1-Investor-Slide" src={AB1_Investor_Slide_Mobile} alt="" />
      </div>
      </div>
      <div className="iq-root">
        <div className="iq-title">
        IQ Platform delivers best options for off-fleet sourcing via our Proprietary Pricing Algorithm & SaaS Platform in a single source system.
        </div>
          <img className="icon" src={aircraft_img} alt="" />
      </div>
      
      <div className="iq-container">
        <div className="iq-statements">
          {statements.map((statement, i) => {
            return (
              <div key={i} className="Statement">
                <img className="icon" src={statement.icon} alt="" />
                {i!==2 ? <h4 className="text">{statement.statement}</h4>: <h4 className="text" style={{ width: '260px'}}>{statement.statement}</h4>}
              </div>
            );
          })}
        </div>
      </div>
      <div className="iq-empty"></div>

      <div className="iq-frames">
        <ImageSideIQComponent />
      </div>

      <div className="iq-demo">
        <div className="iq-root-mission">
          <h1 className="iq-mission">
            Mission sourcing and booking. SIMPLIFIED.
          </h1>
        </div>
        <div className="iq-setup-mission" style={{paddingBottom: '150px'}}>
          <a href="https://meetings.hubspot.com/sales-ab1" target="_blank">
            <button className="iq-setup-demo" >Setup a Demo</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default IQPlatform;
