import React, { useEffect, useState , useSelector} from "react";
import { Auth } from "aws-amplify";
import "./Login.scss";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import { UPDATE_ADDONS } from "../../middleware/constants/aircarft.constants";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import Modal from "../../components/Modal";
import SocialMediaLinks from "./SocialMediaLinks.js";
import CreateAccount from "./CreateAccount.js";
import { UPDATE_AUTH_DATA } from "../../middleware/constants/auth.constants";
//import useLoginAuthentication from "../../hooks/useLoginAuthentication.js";

const Login = ({
  history,
  updateAddOns,
  selectedAircraft = {},
  selectedAddOns = [],
  login,
  resetSearchResults,
  modalHandler,
  updateLoginStatus
}) => {
 // const logAuth = useLoginAuthentication();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wait, setWait] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [code, setCode] = useState("");
  let path = history?.location?.state?.path;
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  //let searching = history?.location?.state?.book-flight;
  let review = history?.location?.state?.review;
  const [color, setColor] = useState("continue");


  useEffect(() => {
    if (
      email!== "" &&
      password !== "" 
    ) {
      setColor("yellow");
    } else {
      // background: #848B94;

      setColor("continue");
    }
  }, [email,password]);

  useEffect(() => {
    let register = history?.location?.state?.success;
    if (register) {
      toast.success("Signed up. Please login to continue.");
    }
  }, []);
  useEffect(() => {
    if (wait && review && selectedAircraft.bookingId) {
      history.push(`/review-booking/${selectedAircraft.bookingId}`);
    }
  }, [selectedAircraft.bookingId]);

  const accountCreation = () => {
  if(modalHandler!==undefined){
    modalHandler("createAccount")
  }else{
    <Modal show={modal} close={handleClose} preventClose={true}
    >
      <CreateAccount/>
    </Modal>
  }
  }

  const isFieldValid= (e) => {
    // const { name, value } = e.target;
    // if (name === 'email' && value !== "") {
    //   if(password===""){
    //   setPassword("aaa")
    // }
    //  handleLogin(e)
    // }
  }

  const handleLogin = (e) => {
    e.preventDefault();

     let pswd =null;
     if(password===null || password===""){
      pswd="nopswd"
     }else{
      pswd=password
     }

      setLoader(true);
      Auth.signIn({ username: email, password:pswd })
        .then((res) => {
          console.log(res.attributes)
          //console.log(userData)
          const userData = res.attributes;
           updateLoginStatus(true,userData, false);

          if (review) {
            updateAddOns({
              addonIds: selectedAddOns,
              aboneSearchId: selectedAircraft.aboneSearchId,
            });
            setWait(true);
          } else if (login === "Booking") {
            // window.location.href = '/';
            history.push("/searching");
            // resetSearchResults({});
          } else if (path) {
            history.push(path);
          } else {
            setLoader(false);
            window.location.href = "/";
          }
        })
        .catch((err) => {
          console.log(`Error signing up: ${JSON.stringify(err)}`);
          if (err.code === "UserNotConfirmedException") {
            setLoader(true);
            Auth.resendSignUp(email)
              .then(() => {
                setModal(true);
                setLoader(false);
              })
              .catch((err) => {
                console.log(err, "err");
                //toast.error(err.message);
                //setEmailError(true)
              });
          } 
            if(err.message==="User does not exist."){
              // toast.error(err.message)
              setEmailError(true)
             }else{
              setEmailError(false)
             }

             if(err.message==="Incorrect username or password."){
              // toast.error(err.message)
              setPasswordError(true)
             }else{
              setPasswordError(false)
             }

            //setEmailError(true)
           // toast.error(err.message);
          
          setLoader(false);
        });

   };

  const handleClose = () => {
    setModal(false);
    localStorage.removeItem("socialLogin");
    Auth.signOut({})
  };

  const confirmSignUpCode = (e) => {
    e.preventDefault();
    if (code !== "") {
      setLoader(true);
      Auth.confirmSignUp(email, code)
        .then(() => {
          setModal(false);
          toast.success("Awesome! Successfully Verified.");
          Auth.signIn({ username: email, password }).then((res) => {
            setLoader(false);
            window.location.href = "/";
          });
          //handleLogin();
          //history.push('/login');
        })
        .catch((err) => {
          
          console.error(err);
          setLoader(false);
          if(err.message==="User does not exist"){
            // toast.error(err.message)
            setEmailError(true)
           }
         // toast.error(err.message)
          
          //toast.error(err.message);
        });
    } else {
      //toast.error("Please type the code.");
    }
  };
 // console.log("modalHandler type:", typeof modalHandler);
  return (
    <div className="login-root">
      <LoadingOverlay active={loader} spinner text="Loading...">
        <h1 className="title">Login</h1>
        <div style={{display:'flex'}}>
        <p className="newUser">New user? </p>
        <Button
            label={'Create an account'}
            type={'yellow-link'}
            onClick={accountCreation}
            size={'small'}
          />
        {/* <Button
            label={'Create an account'}
            type={'yellow-link'}
            fullWidth={'false'}
            onClick={() => modalHandler("createAccount")}
            size={'small'}
          />  */}
          </div>
        <form className="login-form">
          <InputBox
            type="email"
            name="email"
            title="Please enter email"
            placeholder="Email"
            className={`input-grp ${emailError && "error"}`}
            value={email}
            autoComplete="off" 
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => isFieldValid(e)}
          />
          {emailError && <p className="danger">We don’t have an account with that username.</p>}
          <InputBox
            type="password"
            name="password"
            title="Please enter password"
            placeholder="Password"
            className={`input-grp ${passwordError && "error"}`}
            value={password}
            autoComplete="off" 
            //onBlur={(e) => isFieldValid(e)}
            onChange={(e) => setPassword(e.target.value)}
            
          />
           {passwordError && <p className="danger">Incorrect username or password.</p>}
          <Button
            btnAction={"submit"}
            label={"Login"}
           // type={"yellow"}
           type={color}
            size={"large"}
            onClick={handleLogin}
          />
          {/* <Button
            label={'Already have an account? SignUp'}
            type={'white-link'}
            onClick={() => modalHandler("createAccount")}
            size={'small'}
          /> */}
          
          {/* <Button
            label={"Don't have an account? Sign Up"}
            type={'white-link'}
            onClick={() => history.push('/create-account')}
            size={'small'}
          />*/}
          {/* <Button
            label={'Forgot password?'}
            type={'white-link'}
            onClick={() => history.push('/forgot-password')}
            size={'small'}
          />  */}
          {/* <SocialMediaLinks/> */}
        </form>
        <SocialMediaLinks value="Login" />
        <div className="confirm-mail">
          <Modal show={modal} close={handleClose}>
            <div>
              <p>A confirmation code has been sent to your email {email}</p>
              <form>
                <InputBox
                  theme={"light"}
                  type="text"
                  title="Please type the Code"
                  placeholder="Verification Code"
                  className="input-grp"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button
                  btnAction={"submit"}
                  label={"Confirm"}
                  type={"yellow"}
                  size={"small"}
                  onClick={(e) => confirmSignUpCode(e)}
                />
              </form>
            </div>
          </Modal>
        </div>
      </LoadingOverlay>
    </div>
  );
};

function mapStateToProps({ aircraft }) {
  return {
    selectedAircraft: aircraft.selectedAircraft,
    selectedAddOns: aircraft.selectedAddOns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAddOns: (data) => dispatch({ type: UPDATE_ADDONS, payload: data }),
    updateLoginStatus : (status, userData, createAccount) => dispatch({ type: UPDATE_AUTH_DATA, loginStatus : status , userData : userData,createAccount: createAccount})
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
