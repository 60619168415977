import React, { useEffect } from "react";
import moment from "moment";
import { Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import EmptyIcon from "../../assets/icons/empty_icon.png";
import fontRegular from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Regular.otf";
import fontMedium from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Medium.otf";
import fontBold from "../../assets/font/FKGroteskNeue-1.1.1/otf/FKGroteskNeue-Bold.otf";
const lookup = require('country-code-lookup');

const styles = StyleSheet.create({
  font: {
    fontFamily: "FKGroteskNeue",
    fontWeight: "normal",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    // marginTop: 10
  },
  headerContainer: {
    backgroundColor: "#4782E7",
  },
  tripLegHeader: {
    padding: "5px 15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: 60
  },
  legNumber: {
    fontSize: 16,
    color: "#FFF",
    fontWeight: 700,
    margin: 0,
  },
  category: {
    fontSize: 12,
    fontWeight: 600,
    margin: 0,
    color: "#FFF",
  },
  info: {
    margin: "20px auto",
    padding: "0 3%",
    fontSize: 14,
    marginBottom: "auto"
  },
  flightInfo1: {
    display: "flex",
    flexDirection: "row",
  },
  flightInfoDate: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 36,
  },
  heading: {
    paddingLeft: 5,
    paddingBottom: 10,
    fontSize: 11,
    color: "#848b94",
    fontWeight: 600,
  },
  place: {
    paddingLeft: 5,
    display: "flex",
    flexDirection: "row",
    width: "70%",
    marginBottom: 16,
    minHeight: 38.8,
    fontSize: 10
  },
  code: {
    fontWeight: "semibold",
  },
  display: {
    paddingLeft: 3
  },
  travelContainer: {
    flexBasis: "34.94%",
  },
  dateTime: {
    backgroundColor: "#4782E7",
    padding: "5px 10px",
    fontWeight: "bold",
    borderRadius: 8,
    width: "auto",
    fontSize: 10,
    marginLeft: 0,
    color: "#FFF",
    marginRight: 10,
    textAlign: "center",
    marginTop: -10
  },
  duration: {
    flexBasis: "16%",
  },
  padding: {
    paddingLeft: 5,
    fontSize: 10
  },
  tailNo: {
    flexBasis: "14.28%",
  },
  flightInfo2: {
    display: "flex",
    flexDirection: "row",
    marginTop: -10
  },
  fboContainer: {
    flexBasis: "30%",
  },
  crewContainer: {
    flexBasis: "40%",
  },
  heading2: {
    paddingLeft: 5,
    color: "#848b94",
    fontWeight: 600,
    fontSize: 11
  },
  fboAirport: {
    paddingLeft: 5,
    fontWeight: 600,
    margin: 0,
    fontSize: 10,
    marginTop: "15px",
    paddingBottom: 3
  },
  fboAirport2: {
    paddingLeft: 5,
    fontWeight: 600,
    margin: 0,
    fontSize: 10,
    marginTop: "30px",
    paddingBottom: 2
  },
  fboText: {
    paddingLeft: 5,
    fontSize: 10
  },
  crew: {
    fontSize: 13,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: 0,
    margin: 0,
  },
  crewBorder: {
    fontSize: 13,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid #cfcdcd",
  },
  crewHead: {
    color: "#848b94",
    fontWeight: 600,
    flexBasis: "30%",
    fontSize: 11,
    textAlign: "left",
    marginBottom: "15px"
  },
  crewMid: {
    color: "#848b94",
    textAlign: "left",
    fontWeight: 600,
    flexBasis: "30%",
    fontSize: 11,
    marginBottom: "15px"
  },
  crewEnd: {
    textAlign: "left",
    color: "#848b94",
    fontWeight: 600,
    flexBasis: "40%",
    fontSize: 11,
    marginBottom: "10px"
  },
  value1: {
    flexBasis: "30%",
    fontSize: 10,
    textAlign: "left",
    margin: "8px, 0"
  },
  value2: {
    textAlign: "center",
    flexBasis: "30%",
    fontSize: 10,
    margin: "8px, 0"
  },
  value3: {
    textAlign: "left",
    flexBasis: "40%",
    fontSize: 10,
    margin: "8px, 0"
  },
  passengersHeading: {
    margin: "30px 0",
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 10
  },
  addOnHeading: {
    margin: "30px 0",
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 10
  },
  passengerContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0",
    borderBottom: "1px solid #cfcdcd",
  },
  passengerContainerNoBorder: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0",
    border: 0,
  },
  passengerHeadContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  addonHeadContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15
  },
  noteHeadContainer: {
    display: "flex",
    flexDirection: "row",
  },
  number: {
    width: 30,
    fontSize: 10
  },
  passengerEnd: {
    width: 180,
    textAlign: "center",
    fontSize: 10
  },
  passengerHeadEnd: {
    width: 180,
    textAlign: "left",
    color: "#848b94",
    fontWeight: 600,
    fontSize: 11
  },
  passengerStart: {
    width: 180,
    textAlign: "start",
    fontSize: 10
  },
  leadPassenger: {
    textAlign: "start",
    fontSize: 10
  },
  passengerHeadStart: {
    width: 180,
    textAlign: "start",
    color: "#848b94",
    fontWeight: 600,
    fontSize: 11
  },
  passengerCell: {
    width: 160,
    textAlign: "left",
    fontSize: 10,
  },
  passengerHeadCell: {
    width: 160,
    textAlign: "left",
    color: "#848b94",
    fontWeight: 600,
    fontSize: 11
  },
  addOnHeadCell: {
    width: 120,
    textAlign: "left",
    fontSize: 10,
  },
  addOnSubHeadCell: {
    fontSize: 10,
    textAlign: "left",
    width: 30,
  },
  empty: {
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyHeading: {
    paddingTop: 5,
    fontSize: 14,
    color: "#848b94",
    fontWeight: 600,
  },
  emptyImg: {
    width: 60,
    height: 60,
  },
  addOn: {
    display: "flex",
    flexDirection: "row",
    padding: "30px 0",
  },
  addonSub: {
    width: 5,
    float: "left",
    display: "inline-block",
  },
  addonSubSymbol: {
    paddingLeft: 1,
    paddingRight: 1,
    float: "left",
    display: "inline-block",
  },
  infoBox: {
    padding: "20px 30px",
    // marginBottom: 20,
    backgroundColor: "#fff",
    borderRadius: 15,
    border: "1.5px solid #ccc",
    height: "auto"
  },
  row: {
    padding: "5px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  noteStyle: {
    marginTop: -15,
    marginLeft: -15,
    fontSize: 10,
    marginBottom: 3
  },
  subHeading: {
    fontSize: 10,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10
  },
});

Font.register({
  family: "FKGroteskNeue",
  fonts: [
    {
      format: "otf",
      src: fontRegular,
      fontStyle: "normal",
      fontWeight: "normal",
    },
    {
      format: "otf",
      src: fontMedium,
      fontStyle: "normal",
      fontWeight: "semibold",
    },
    { format: "otf", src: fontBold, fontStyle: "normal", fontWeight: "bold" },
  ],
});

const LegPDF = ({ data, status, tailNumber }) => {
  const arrDate = moment(
    data?.arrivalDateTime,
    "dddd DD/MM/YYYY hh:mm A"
  ).format("dddd MM/DD/YYYY");

  // const arrTime = moment(
  //   data?.arrivalDateTime,
  //   "dddd DD/MM/YYYY hh:mm A"
  // ).format("hh:mm A");


  //const arrDate = ''
  const arrTime = ''

  const deptDate = moment(
    data?.departureDateTime,
    "dddd MM-DD-YYYY hh:mm A"
  ).format("dddd MM/DD/YYYY");

  const deptTime = moment(
    data?.departureDateTime,
    "dddd MM-DD-YYYY hh:mm A"
  ).format("hh:mm A");

  let tailNumbers = ""
  if (tailNumber === null) {
    tailNumbers = "N/A"
  } else {
    tailNumbers = tailNumber
  }

  function camelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str;
      const strArray = strVal.split('_');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return ' ';
    }

  }

  function initCamelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str.toLowerCase(str);
      const strArray = strVal.split(' ');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return ' ';
    }

  }

  function getCountryCode(country) {
    if (country) {
      const countrySelected = lookup.byCountry(country);
      if (countrySelected) {
        return countrySelected.iso3;
      } else {
        return 'N/A'
      }
    } else {
      return 'N/A'
    }

  }

  function splitCrewName(str) {
    if (str) {
      const strArray = str.split(' ');
      let strVal;
      for (let i = 0; i < strArray.length; i++) {
        if (i === 0) {
          strVal = strArray[i];
        } else {
          strVal = strVal + '\n' + strArray[i];
        }
      }
      return strVal;
    } else {
      return 'N/A';
    }

  }

  function splitByLength(str, type) {
    let originData = [];
    let originName = '';
    if (type === 'passportNumber') {
      if (str) {
        if (str.length > 10) {
          originData = str.match(/(.{1,10})/g);
        } else {
          originData[0] = str;
        }
      } else {
        originData = [];
      }
    } else if (type === 'passportName') {
      if (str) {
        if (str.length > 12) {
          originData = str.match(/(.{1,12})/g);
        } else {
          originData[0] = str;
        }
      } else {
        originData = [];
      }
    }
    if (originData.length > 0) {
      originData.forEach(function (key, index) {
        if (index + 1 !== originData.length) {
          originName = originName + key + '\n';
        } else {
          originName = originName + key;
        }
      });
    } else {
      originName = "N/A";
    }
    return originName;
  }

  function splitByFboLength(str, type) {
    let originData = [];
    let originName = '';
    if (type === 'fbo') {
      if (str) {
        str = str.replace(/\n/g, " ")
        if (str.length > 25) {
          originData = str.match(/.{1,25}(\s|$)/g);
        } else {
          originData[0] = str;
        }
      } else {
        originData = [];
      }
    }

    if (originData.length > 0) {
      originData.forEach(function (key, index) {
        if (index + 1 !== originData.length) {
          originName = originName + key + '\n';
        } else {
          originName = originName + key;
        }
      });
    } else {
      originName = "";
    }
    return originName;
  }
  let addonIndex = 0;

  return (
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.font}>
        <View style={styles.headerContainer}>
          <View style={styles.tripLegHeader}>
            <Text style={styles.legNumber}>LEG #{data?.legOrder}</Text>
            <View>
              <Text style={styles.category}>Booked Aircraft</Text>
              <Text style={styles.category}>
                Category: {data?.aircraftCategory}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.info}>
          <View style={styles.flightInfo1}>
            <View style={styles.travelContainer}>
              <Text style={styles.heading}>Departure</Text>
              <View style={styles.place}>
                <Text style={styles.code}>
                  {data?.departureAirport?.icaoCode}&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
                <Text style={styles.display}>
                  {data?.departureAirport?.displayName}
                </Text>
              </View>
            </View>
            <View style={styles.travelContainer}>
              <Text style={styles.heading}>Destination</Text>
              <View style={styles.place}>
                <Text style={styles.code}>
                  {data?.arrivalAirport?.icaoCode}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Text>
                <Text style={styles.display}>
                  {data?.arrivalAirport?.displayName}
                </Text>
              </View>
            </View>
            <View style={styles.duration}>
              <Text style={styles.heading}>Duration</Text>
              <Text style={styles.padding}>{data?.flightTime}</Text>
            </View>
            <View style={styles.tailNo}>
              <Text style={styles.heading}>Tail #</Text>
              <Text style={styles.padding}>{data?.tailNumber || tailNumbers}</Text>
            </View>
          </View>
          <View style={styles.flightInfoDate}>
            <View style={styles.travelContainer}>
              <View style={styles.dateTime}>
                <Text>{deptDate.toUpperCase()}</Text>
                <Text>{deptTime.toUpperCase()}</Text>
              </View>
            </View>
            {/* <View style={styles.travelContainer}>
              <View style={styles.dateTime}>
                <Text>{arrDate.toUpperCase()}</Text>
                <Text>{arrTime.toUpperCase()}</Text>
              </View>
            </View> */}
          </View>
          <View style={styles.flightInfo2}>
            <View style={styles.fboContainer}>
              <Text style={styles.heading2}>*FBO</Text>
              <Text style={styles.fboAirport}>
                {splitByFboLength(data?.fboDetails?.originFbo?.name, 'fbo')}
              </Text>
              <Text style={styles.fboText}>
                {splitByFboLength(data?.fboDetails?.originFbo?.address, 'fbo')}
              </Text>
              <Text style={styles.fboText}>
                {data?.fboDetails?.originFbo?.contact || ""}
              </Text>
            </View>
            <View style={styles.fboContainer}>
              <Text style={styles.fboAirport2}>
                {splitByFboLength(data?.fboDetails?.destinationFbo?.name, 'fbo')}
              </Text>
              <Text style={styles.fboText}>
                {splitByFboLength(data?.fboDetails?.destinationFbo?.address, 'fbo')}
              </Text>
              <Text style={styles.fboText}>
                {data?.fboDetails?.destinationFbo?.contact || ""}
              </Text>
            </View>
            <View style={styles.crewContainer}>
              <View style={styles.crew}>
                <Text style={styles.crewHead}>Crew</Text>
                <Text style={styles.crewMid}>Position</Text>
                <Text style={styles.crewEnd}>Cell Phone</Text>
              </View>
              {data?.crews?.map((crew, i) => {
                return (
                  <View
                    style={
                      i === data?.crews?.length - 1
                        ? styles.crew
                        : styles.crewBorder
                    }
                    key={i}
                  >
                    <Text style={styles.value1}>{splitCrewName(crew.name)}</Text>
                    <Text style={styles.value2}>{crew.position || "N/A"}</Text>
                    <Text style={styles.value3}>{crew.contact || "N/A"}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View>
            <Text style={styles.passengersHeading}>PASSENGERS</Text>
            <View style={styles.passengerHeadContainer}>
              <Text style={styles.number}>{""}</Text>
              <Text style={styles.passengerHeadStart}>First Name/MI</Text>
              <Text style={styles.passengerHeadCell}>Last Name</Text>
              <Text style={styles.passengerHeadCell}>DOB</Text>
              <Text style={styles.passengerHeadCell}>Passport #</Text>
              <Text style={styles.passengerHeadCell}>Expiration</Text>
              <Text style={styles.passengerHeadEnd}>Country Issued</Text>
            </View>
            {data?.travellers?.map((traveller, i) => {
              return (
                <View
                  style={
                    i === data?.travellers?.length - 1
                      ? styles.passengerContainerNoBorder
                      : styles.passengerContainer
                  }
                  key={i}
                >
                  <Text style={styles.number}>{i + 1}</Text>
                  {status?.id === traveller?.id ? (
                    <>
                      <Text style={styles.passengerStart}>
                        {!traveller.firstName
                          ? "N/A"
                          : initCamelize(traveller.firstName)}
                        {
                          !traveller.middleName
                            ? " "
                            : "/" + initCamelize(traveller.middleName)}
                        <View>
                          <Text style={styles.leadPassenger}>{"\n" + "(Lead Passenger)"}</Text>
                        </View>

                      </Text>
                    </>
                  ) : (
                    <Text style={styles.passengerStart}>
                      {!traveller.middleName
                        ? splitByLength(initCamelize(traveller.firstName), 'passportName')
                        : splitByLength(initCamelize(traveller.firstName) + "/" + initCamelize(traveller.middleName), 'passportName')}

                    </Text>
                  )}
                  <Text style={styles.passengerCell}>
                    {splitByLength(initCamelize(traveller.lastName), 'passportName')}
                  </Text>
                  <Text style={styles.passengerCell}>
                    {traveller.dob || "N/A"}
                  </Text>
                  <Text style={styles.passengerCell}>
                    {splitByLength(traveller.passportNumber, 'passportNumber')}
                  </Text>
                  <Text style={styles.passengerCell}>
                    {traveller.passportExpiry || "N/A"}
                  </Text>
                  <Text style={styles.passengerEnd}>
                    {getCountryCode(traveller.passportCountry)}
                  </Text>
                </View>
              );
            })}
            {data?.travellers?.length === 0 && (
              <View style={styles.empty}>
                <Image src={EmptyIcon} style={styles.emptyImg} />
                <Text style={styles.emptyHeading}>
                  No Passengers on the leg
                </Text>
              </View>
            )}
          </View>
          <View>


            {data?.selectedAddOns?.map((p, i) => {
              if (p.disabled === false) {
                addonIndex = addonIndex + 1;
                if (addonIndex === 1) {
                  return (
                    <View wrap={false}>
                      <View>
                        <Text style={styles.addOnHeading}>ADDITIONAL SERVICES</Text>
                      </View>
                      <View>
                        <View style={styles.addonHeadContainer}>
                          <Text style={styles.addOnHeadCell}>
                            {camelize(p.addOnEntity.addonType)}
                          </Text>
                          <Text style={styles.addOnSubHeadCell}>-</Text>
                          <Text style={styles.addOnHeadCell}>
                            {p.addOnEntity.displayName}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                } else {
                  return (
                    <View>
                      <View style={styles.addonHeadContainer}>
                        <Text style={styles.addOnHeadCell}>
                          {camelize(p.addOnEntity.addonType)}
                        </Text>
                        <Text style={styles.addOnSubHeadCell}>-</Text>
                        <Text style={styles.addOnHeadCell}>
                          {p.addOnEntity.displayName}
                        </Text>
                      </View>
                    </View>
                  );
                }
              }
            })}
          </View>



          {data.miscellaneousNotes ||
            data.groundTransportationNotes ||
            data.cateringNotes ? (
            <View>
              {
                data.groundTransportationNotes ? (

                  <View wrap={false}>
                    <View>
                      <Text style={styles.passengersHeading}>NOTES</Text>
                    </View>
                    <View style={styles.noteHeadContainer}>
                      <Text style={styles.subHeading} >Ground Transportation Notes
                     </Text>
                    </View>
                    <View style={styles.infoBox}>
                      <View style={styles.row}>
                        <Text style={styles.noteStyle}> {data?.groundTransportationNotes}</Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View></View>
                )}
              {
                data.cateringNotes ? (
                  <View wrap={false}>
                    {!data.groundTransportationNotes ? (
                      <View>
                        <Text style={styles.passengersHeading}>NOTES</Text>
                      </View>
                    ) : (<View></View>)}
                    <View style={styles.noteHeadContainer}>
                      <Text style={styles.subHeading}>Catering Notes
                     </Text>
                    </View>
                    <View style={styles.infoBox}>
                      <View style={styles.row}>
                        <Text style={styles.noteStyle}> {data?.cateringNotes}</Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View></View>
                )}
              {
                data.miscellaneousNotes ? (
                  <View wrap={false}>
                    {!data.cateringNotes && !data.groundTransportationNotes ? (
                      <View>
                        <Text style={styles.passengersHeading}>NOTES</Text>
                      </View>
                    ) : (<View></View>)}
                    <View style={styles.noteHeadContainer}>
                      <Text style={styles.subHeading}>Miscellaneous Notes
                  </Text>
                    </View>
                    <View style={styles.infoBox}>
                      <View style={styles.row}>
                        <Text style={styles.noteStyle}> {data?.miscellaneousNotes}</Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View></View>
                )}


            </View>
          ) : (
            <View></View>
          )}
        </View>
      </View>
    </Page>
  );
};

export default LegPDF;
