import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import InputBox from "../../components/InputBox";
import Button from "../../components/Button";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import Login from "./Login";
import "./Login.scss";
import { POST_AUTH,POST_AUTH_SYNC } from "../../utils/HTTP.utils";
//import {getIsUaAuthentication} from "../../hooks/useAuthentication"
import { API_PROFILE } from "../../middleware/constants/url.constants";
import { connect } from "react-redux";
import useAuthentication from "../../hooks/useLoginAuthentication";
import { useSelector } from "react-redux";
import { UPDATE_AUTH_DATA } from "../../middleware/constants/auth.constants";

function CreateAccount({ modalHandler ,  userData, modalclose, updateLoginStatus }) {
  const logAuth = useAuthentication();
  const userInfo = useSelector((state) => state.auth.userData);

  const [loader, setLoader] = useState(false);
  const [color, setColor] = useState("continue");
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [fields, setFields] = useState({
    firstName: userData?.firstname || "",
    lastName:  userData?.lastname || "",
    phoneNumber:  userData?.phone || "",
    email:  userData?.email || ""
    //telCode : "+1",
  });

  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    invalidEmail: false,
    invalidphoneNo: false,
    //telCode : false,
  });

  const handleChange = (e) => {
    setFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
  isFormValidOnSubmit()
}, []);

  useEffect(() => {
    if (
      fields.firstName !== "" &&
      fields.lastName !== "" &&
      fields.email !== "" &&
      fields.password !== "" &&
      fields.phoneNumber !== ""       
    ) {
      setColor("yellow");
    } else {

      setColor("continue");
    }
  }, [fields,invalidEmailError]);

  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValidOnSubmit()) {
      const socialLogin = localStorage.getItem("socialLogin")
   
      if(socialLogin === "yes") {
       // console.log(fields)
        
        localStorage.removeItem("socialLogin")
        const firstname = fields?.firstName;
        const lastname = fields?.lastName;
        const email = fields?.email;
        const ph = fields?.phoneNumber;
        const phone = "+1"+ph;
      
        if (firstname && firstname !== "null" && firstname !== "") {

          logAuth.getIsUaAuthentication(userInfo)
            .then(({ token }) => {
              const res =  POST_AUTH_SYNC(
                API_PROFILE,
                {
                  firstname,
                  lastname,
                  email,
                  phone,
                },
                token
              ).then((res) => {
                console.log(res)
                if(res.status === 200) {
                  toast.success("Changes Saved Successfully")
                  userData = {
                    firstname : firstname,
                    lastname : lastname,
                    email : email,
                    phone : phone
                  }
                  updateLoginStatus(true,userData, false);
                  modalclose();
                  window.location.reload();
                }
              });
              
            })
            .then(() => {
              // toast.success("Changes Saved Successfully")
              // userData = {
              //   firstname : firstname,
              //   lastname : lastname,
              //   email : email,
              //   phone : phone
              // }
              // updateLoginStatus(true,userData, false);
              // modalclose();

            });
        }
      }
      else{
        modalHandler("createPassword", fields);

      }
    }
  };

  const isFormValidOnSubmit = () => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
   // let phoneRegex = /^\d/;
    const errors = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
      email: false,
      invalidEmail: false,
      invalidphoneNo: false,
    };

    if (fields.firstName === "") {
      errors.firstName = true;
    }

    if (fields.lastName === "") {
      errors.lastName = true;
    }

    if (fields.phoneNumber === "") {
      errors.phoneNumber = true;
    }
    if (fields.email === "") {
      errors.email = true;
      setInvalidEmailError(false)
    } else {
      if (!emailRegex.test(fields.email)) {

        errors.invalid=true;
        setInvalidEmailError(true)
      }else{

        errors.invalid=false;
        setInvalidEmailError(false)
      }
    }
    console.log(errors);
    setErrorFields(errors);
    if ((Object.values(errors).some((error) => error === true))) {

      console.log(invalidEmailError);


      return false;
    }
    return true;
  };


  const isFieldValid = (e) => {
    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const { name, value } = e.target;
  
    let errors = false;
  
    if (name === 'firstName' && value === "") {
      errors = true;
    }
  
    if (name === 'lastName' && value === "") {
      errors = true;
    }
  
    if (name === 'phoneNumber' && value === "") {
      errors = true;
    }
  
    if (name === 'email' && value === "") {
      errors = true;
      setInvalidEmailError(false)
    } 

    //  else if (name === 'email' && !emailRegex.test(value)) {
    //  // errors=true;
    //  setErrorFields((prev) => ({
    //   ...prev,
    //   invalidEmail: true,
    // }));
    // }
  
    setErrorFields((prev) => ({
      ...prev,
      [name]: errors,
      //invalidEmail: false,
    }));
  };



  useEffect(() => {  
    if (userData?.hasOwnProperty("firstname")) {
      setFields((prev) => ({
        ...prev,
        "firstName": userData.firstname,
      }));
    }
    else if (userData?.hasOwnProperty("lastname")) {
      setFields((prev) => ({
        ...prev,
        "lastName": userData.lastname,
      }));
    }
    else if (userData?.hasOwnProperty("phone")) {
      setFields((prev) => ({
        ...prev,
        "phoneNumber": userData.phone,
      }));
    }
    else if (userData?.hasOwnProperty("email")) {
      console.log(userData.email)
      setFields((prev) => ({
        ...prev,
        "email": userData.email,
      }));
    }


  }, [userData]);

  return (
    <div className="login-root">
      <LoadingOverlay active={loader} spinner text="Loading...">
        <h1 className="title">Create</h1>
        <h1 className="title">Account</h1>
        <form className="login-form">
          <InputBox
            type="firstName"
            title="Please enter First Name"
            placeholder="First Name"
            className={`input-grp ${errorFields.firstName && "error"}`}
            name="firstName"
           // value={userData?.firstname ?? ""}
            onChange={handleChange}
            onBlur={isFieldValid}
            //onFocus={handleFocus}
            value={fields.firstName}

          />
          {errorFields.firstName && <p className="danger">Field required</p>}

          <InputBox
            type="lastName"
            title="Please enter Last Name"
            placeholder="Last Name"
            className={`input-grp ${errorFields.lastName && "error"}`}
            name="lastName"
            //value={userData?.lastname ?? ""}
            onChange={handleChange}
            onBlur={isFieldValid}
            value={fields.lastName}

            //onFocus={handleFocus}
          />
          {errorFields.lastName && <p className="danger">Field required</p>}

          {/* <div className='cust-no'>
            <input
              type='string'
              title='Please enter country code'
              placeholder="Code"
              className='input-grp tel-number'
              defaultValue= '+1'
              name="telCode"
              onChange={handleChange}
              onFocus={handleFocus}
            /> */}
            {/* ... (other InputBox components) */}
          

          <InputBox
            type='number'
            title="Please enter Phone Number"
            placeholder="Phone Number"
            className={`input-grp ${(errorFields.phoneNumber || errorFields.invalidphoneNo) && "error"}`}
            name="phoneNumber"
           // value={userData?.phone ?? ""}
            onChange={handleChange}
            //onFocus={handleFocus}
            onBlur={isFieldValid}
            value={fields.phoneNumber}
          />
          {/* </div> */}
          {errorFields.phoneNumber && <p className="danger">Field required</p>}
          {errorFields.invalidphoneNo && (
            <p className="danger">Invalid Phone Number</p>
          )}

          <InputBox
            type="email"
            title="Please enter email"
            placeholder="Email"
            className={`input-grp ${(errorFields.email || errorFields.invalidEmail) && "error"}`}
            name="email"
            onChange={handleChange}
           // onFocus={handleFocus}
           onBlur={isFieldValid}
           value={fields.email}
          />
          {errorFields.email && <p className="danger">Field required</p>}
          {invalidEmailError && <p className="danger">Invalid email</p>}

          <div style={{ marginTop: '20px' }}>
            <Button
              btnAction={"submit"}
              label={"Continue"}
              type={color}
              size={"large"}
              onClick={(e) => handleSubmit(e)}
            />
          </div>
        </form>
      </LoadingOverlay>

      <div className="message-ca">
        “You will receive $3,000 in Flight Credits for setting up a Free Account with AIRBOOK ONE”.
      </div>
    </div>
  );
}


function mapStateToProps({ auth }) {
  return {
    loginStatus : auth.loginStatus,
    loginUserData : auth.userData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateLoginStatus : (status, userData, createAccount) => dispatch({ type: UPDATE_AUTH_DATA, loginStatus : status , userData : userData, createAccount : createAccount})
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
