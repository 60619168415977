import {
    UPDATE_AUTH_DATA
  } from "../constants/auth.constants";
  
  const initialState = {
    loginStatus : false,
    userData : {},
    createAccount : false
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_AUTH_DATA: {
        return {...state, loginStatus: action.loginStatus, userData : action.userData, createAccount : action.createAccount}
      }
    
      default: {
        return state
      }
    }
  };
  