import React from "react";
import "./ImageSideIQComponent.scss";
import Frame1 from "../../assets/icons/iq-img1.png";
import Frame2 from "../../assets/icons/iq-img2.png";
import Frame3 from "../../assets/icons/iq-img3.png";
import {Link} from 'react-router-dom';

const ImageSideIQComponent = () => {
  return (
    <div className="image-side-section">
      <div className="img-side-left frame1">
        <img src={Frame1} alt={"Frame1"} className="image-left" />
        <div className="content">
          <h3 className="headline-h3">Find aircraft that are ‘mission ready’ at your fingertips</h3>
          <p className="w330 body-font-p">
          We provides 24/7 Data Operations Team for operators, allowing both operators and their customers access to a 'vetted and efficient, marketplace.
          </p>
        </div>
      </div>
      <div className="img-side-right frame2">
        <div className="content">
          <h3 className="headline-h3">Save booking acquisition time by 75%+</h3>
          <p className="w422 body-font-p">
          Show your charter clients your self-branded app and booking widget in order to streamline the booking process for all.
          </p>
        </div>
        <img src={Frame2} alt={"Frame2"} className="image-right" />
      </div>
      <div className="img-side-left frame3">
        <img src={Frame3} alt={"Frame3"} className="image-left" />
        <div className="content">
          <h3 className="headline-h3">Market Your entire Fleet at Low Upfront Costs</h3>
          <p className="w330 body-font-p">
          Show the marketplace your entire fleet at a 'fixed subscription cost' vs. today's existing offerings, and pay when a mission happens, not before.  Plus, our 'White Labeled" Market Analytic Reports are at your fingertips in real-time, enabling your team to secure more bookings.
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default ImageSideIQComponent;
