import {put, takeLatest} from 'redux-saga/effects'
import Constants from '../constants'
import {GET_UN_AUTH, POST_AUTH, POST_UN_AUTH} from "../../utils/HTTP.utils";
import {API_JET_INFO, API_SEARCH_AIRCRAFT, API_UPDATE_ADDONS} from "../constants/url.constants";
import {getSessionID} from "../../utils/session.storage.utils";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {toast} from "react-toastify";

function* searchAircraft(action) {
  try {
    let data = yield POST_UN_AUTH(API_SEARCH_AIRCRAFT, action.payload);
    if (data.error===null) {
      yield put({type: Constants.SEARCH_AIRCRAFT_SUCCESS, payload: data})
    } else {
      toast.error(data.error);
      yield put({type: Constants.SEARCH_AIRCRAFT_ERROR})
    }
  } catch (error) {
    console.error(error)
  }
}

export function* searchAircraftWatch() {
  yield takeLatest(Constants.SEARCH_AIRCRAFT, searchAircraft)
}


function* getJetIfo() {
  try {
    let data = yield GET_UN_AUTH(API_JET_INFO, {});
    yield put({type: Constants.JET_INFO_SUCCESS, payload: data.data})
  } catch (error) {
    yield put({type: Constants.JET_INFO_ERROR})
    console.error(error)
  }
}

export function* getJetIfoWatch() {
  yield takeLatest(Constants.JET_INFO, getJetIfo)
}


function* updateAddonsUnAuth(action) {
  try {
    let authData = yield getIsUaAuthentication();
    let method = POST_UN_AUTH;
    if (authData.status) method = POST_AUTH;
    let data = yield method(API_UPDATE_ADDONS, {
      "aboneSearchId": action.payload.aboneSearchId,
      "bookingId": "",
      "sessionId": getSessionID(),
      "addonIds": action.payload.addonIds,
      "useRewards": action.payload.useRewards,
      "legDataList":action.payload.legDataList,
    }, authData.token);
    yield put({type: Constants.UPDATE_ADDONS_SUCCESS, payload: data})
  } catch (error) {
    yield put({type: Constants.UPDATE_ADDONS_ERROR})
    console.error(error)
  }
}

export function* updateAddonsUnAuthWatch() {
  yield takeLatest(Constants.UPDATE_ADDONS, updateAddonsUnAuth)
}
