import React,{useState} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {getIsUaAuthentication} from "../../hooks/useAuthentication";


function ProtectedRoute({Component, ...rest}) {
  let history = useHistory();
  let [AuthStatus,setStatus] = useState(false)
  let [loader,setLoader] = useState(true)

  getIsUaAuthentication().then(({status}) => {
    setStatus(status);
    setLoader(false);
  })
  const redirect = () => {
    history.push("/login", {path : rest.path});
  }
  if(!loader){
    return (
      <Route {...rest} render={props => AuthStatus ? <Component {...props} /> : redirect()}/>
    );
  }
  else{
    return(
       null
    );
  }
}

export default ProtectedRoute;
