import React, { useEffect, useState, createRef } from "react";
import { connect } from "react-redux";
import { FaExclamationCircle, FaLock } from "react-icons/fa";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router-dom";
import AttentionIcon from "../../assets/icons/attention.png";
import ReviewBookingHeader from "./ReviewBookingHeader";
import TextLabelTile from "../../components/TextLabelTile";
import Button from "../../components/Button";
import TripCard from "../../components/TripCard";
import FlightAddOns from "../../components/FlightAddOns";
import CostSummary from "../../components/CostSummary";
import LightTextPanel from "../../components/LightTextPanel";
import CompletePayment from "../../components/CompletePayment";
import ScssVariables from "../../styles/_export.module.scss";
import Modal from "../../components/Modal";
import { updateAddOnsOnMaster } from "../BookAFlight/helper";
import { GET_AUTH, POST_AUTH, GET_UN_AUTH } from "../../utils/HTTP.utils";
import useAuthentication, {
  getIsUaAuthentication,
} from "../../hooks/useAuthentication";
import CreditCardDetails from "../../components/CreditCardDetails";
import CheckBox from "../../components/CheckBox";
import UpdatePersonalInfoModal from "../../components/UpdatePersonalInfoModal";
import {
  SET_SELECTED_ADDONS,
  SET_SELECTED_AIRCRAFT,
  UPDATE_ADDONS,
} from "../../middleware/constants/aircarft.constants";
import {
  INIT_PAYMENT,
  PAYMENT_TYPE_PAY_BY_WIRE,
  PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY,
  PAYMENT_TYPE_PAY_NOW,
  PAYMENT_TYPE_DESELECT,
  PAYMENT_TYPE_SUBPAYMENT,
  PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD,
  PAYMENT_TYPE_PAY_NOW_CREDIT_CARD,
  PAYMENT_TYPE_PAY_NOW_WALLET_PAY,
} from "../../middleware/constants/payment.constants";
import {
  API_POST_WALLET_PAY,
  API_PROCESS_TOKEN,
  API_PROFILE,
  API_GET_CONFIGURATION,
} from "../../middleware/constants/url.constants";

import "./ReviewBooking.scss";
import {
  addAcceptScript,
  authenticationToken,
  onApplePayInitiated,
  validateCCDetails,
} from "./helper";
import PageContainer from "../../components/PageContainer";
import CreditCardCharges from "../../components/CreditCardCharges";
import WalletPayBreakDown from "../../components/WalletPayBreakDown";
import { toast } from "react-toastify";
import { sectionFooter } from "@aws-amplify/ui";
import moment from "moment";
import vector from "../../assets/icons/Vector.png";
import CarbonOffset from "../../components/CarbonOffset/CarbonOffset";

function ReviewBooking({
  selectedAddOns = {},
  setSelectedAircraft,
  setSelectedAddOns,
  updateAddOns,
  history,
  selectedPaymentOption,
  subPaymentOption,
  initPayment,
  selectedAircraft: data,
  balance,
  initiatePayment,
  jetInfo,
  searchOptions,
}) {
  const scrollDiv = createRef();
  let [modal, setModal] = useState(false);
  const [terms, setTerms] = useState(true);
  const [loader, setLoader] = useState(true);
  const [ccReset, setReset] = useState(false);
  const [peakStatus, setPeakStatus] = useState(true);
  const [profile, setProfile] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });
  const [config, setConfig] = useState({
    fromDate: "",
    toDate: "",
    message: false,
  });
  const [updateModal, setUpdateModal] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);
  const [btnPeakStatus, btnPeakStatusClick] = useState(false);
  const [btnCheckboxStatus, setCheckboxStatus] = useState(false);
  const [ccPay, setCcPay] = useState({
    name: "",
    number: "",
    expiry: "",
    ccv: "",
  });
  const scrollSmoothHandler = () => {
    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    window.onbeforeunload = function () {
      return "Data will be lost if you leave the page, are you sure?";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    getIsUaAuthentication().then(({ token }) => {
      GET_AUTH(API_PROFILE, token).then((data) => {
        setProfile(data);
        GET_UN_AUTH(API_GET_CONFIGURATION, {
          type: "PEAK_TRAVEL_MESSAGE",
        }).then((res) => {
          if (!res.error) {
            if (res["details"] && res["details"]["bookingScreen"]) {
              setConfig({
                ...config,
                fromDate: res.details.bookingScreen.fromDate,
                toDate: res.details.bookingScreen.toDate,
                message: res.details.bookingScreen.message,
              });
              if (searchOptions && searchOptions["routeType"] != "MULTI_CITY") {
                let deptTime = moment(
                  searchOptions["departureTime"],
                  "DD-MM-YYYY HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss");
                let arrTime = moment(
                  searchOptions["returnTime"],
                  "DD-MM-YYYY HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss");

                if (
                  res.details.bookingScreen.fromDate &&
                  res.details.bookingScreen.toDate
                ) {
                  let fDate = Date.parse(
                    new Date(
                      res.details.bookingScreen.fromDate.replace(/-/g, "/")
                    )
                  );
                  let lDate = Date.parse(
                    new Date(
                      res.details.bookingScreen.toDate.replace(/-/g, "/")
                    )
                  );
                  let cDate = Date.parse(new Date(deptTime.replace(/-/g, "/")));
                  let checkDate = Date.parse(
                    new Date(arrTime.replace(/-/g, "/"))
                  );
                  if (
                    (cDate <= lDate && cDate >= fDate) ||
                    (checkDate <= lDate && checkDate >= fDate)
                  ) {
                    setDateStatus(true);
                    setPeakStatus(false);
                  } else {
                    setDateStatus(false);
                    setPeakStatus(true);
                  }
                } else {
                  setDateStatus(false);
                  setPeakStatus(true);
                }
              } else {
                if (searchOptions["travelCities"].length > 0) {
                  for (
                    let i = 0;
                    i < searchOptions["travelCities"].length;
                    i++
                  ) {
                    let deptTime = moment(
                      searchOptions["travelCities"][i]["departureTime"],
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("YYYY-MM-DD HH:mm:ss");
                    if (
                      res.details.bookingScreen.fromDate &&
                      res.details.bookingScreen.toDate
                    ) {
                      let fDate = Date.parse(
                        new Date(
                          res.details.bookingScreen.fromDate.replace(/-/g, "/")
                        )
                      );
                      let lDate = Date.parse(
                        new Date(
                          res.details.bookingScreen.toDate.replace(/-/g, "/")
                        )
                      );
                      let cDate = Date.parse(
                        new Date(deptTime.replace(/-/g, "/"))
                      );
                      if (cDate <= lDate && cDate >= fDate) {
                        setDateStatus(true);
                        setPeakStatus(false);
                        break;
                      } else {
                        setDateStatus(false);
                        setPeakStatus(true);
                      }
                    } else {
                      setDateStatus(false);
                      setPeakStatus(true);
                    }
                  }
                } else {
                  setDateStatus(false);
                  setPeakStatus(true);
                }
              }
            } else {
              setDateStatus(false);
              setPeakStatus(true);
            }
          } else {
            setDateStatus(false);
            setPeakStatus(true);
          }
        });
      });
    });
  }, []);

  let { id } = useParams();
  let { token } = useAuthentication();
  useEffect(() => {
    setLoader(false);
    if (
      Object.keys(initiatePayment).length > 0 &&
      initiatePayment?.authorizeNetData?.acceptJsUrl
    ) {
      addAcceptScript(initiatePayment.authorizeNetData.acceptJsUrl);
    }
  }, [initiatePayment]);
  useEffect(() => {
    if (data !== null && Object.keys(data || {}).length === 0)
      history.push("/");
    // else fetchData()
    setLoader(false);
  }, [token]);
  const onAddOnChange = (addOnId, value) => {
    let final = [];
    if (value) {
      final = [...selectedAddOns, addOnId];
    } else {
      final = selectedAddOns.filter((a) => a !== addOnId);
    }
    let updatedMaster = updateAddOnsOnMaster(data, final);
    setSelectedAircraft(updatedMaster);
    setSelectedAddOns(final);
    updateAddOns({ addonIds: final, aboneSearchId: data?.aboneSearchId });
  };

  const initiatePaymentAfterUpdate = () => {
    initPayment({
      bookingId: id,
      paymentMethod: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
      paymentMode: selectedPaymentOption,
    });
    setModal(true);
  };

  const sendFinalPaymentCall = async (authData) => {
    let options = {
      paymentMethod: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
      dataValue: authData.dataValue,
      dataDescriptor: authData.dataDescriptor || "COMMON.ACCEPT.INAPP.PAYMENT",
      paymentId: initiatePayment.paymentId,
      mode: selectedPaymentOption,
    };
    let { token } = await getIsUaAuthentication();
    let finalCall = await POST_AUTH(API_PROCESS_TOKEN, options, token);
    if (finalCall.status === "SUCCESS") {
      setLoader(false);
      history.push("/payment-success", {
        finalCall,
        amountDue: data?.priceBreakup?.totalPrice,
        data,
        subType: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
        type: selectedPaymentOption,
      });
    } else {
      setLoader(false);
      setReset(true);
      initPayment({
        bookingId: id,
        paymentMethod: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
        paymentMode: selectedPaymentOption,
      });
      if (authData.resultCode === "Error") {
        authData.message?.map((a) => {
          toast.error(a.text);
        });
      } else {
        setReset(true);
        initPayment({
          bookingId: id,
          paymentMethod: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
          paymentMode: selectedPaymentOption,
        });
        toast.error("Credit card payment failed!");
      }
    }
  };
  const makeWalletPayCall = async () => {
    let { token } = await getIsUaAuthentication();
    let finalCall = await POST_AUTH(
      API_POST_WALLET_PAY,
      { booking: id },
      token
    );
    history.push("/payment-success", {
      amountDue: data?.priceBreakup?.totalPrice,
      finalCall,
      data,
      subType: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
      type: selectedPaymentOption,
    });
  };
  return (
    <PageContainer>
      <div className="ReviewBooking">
        <ReviewBookingHeader />
        <div className="content">
          <h2>Jet Info</h2>
          <TextLabelTile
            title={data?.displayName}
            content={jetInfo[data?.aircraftCategory]?.description}
          />
          {(data.aircraftCategory === "LIGHT_JET" ||
            data.aircraftCategory === "MIDSIZE_JET" ||
            data.aircraftCategory === "TURBO_PROP") && (
            <div className="attention">
              <img className="attention-logo" src={AttentionIcon} />
              <h5 className="attention-info">
                THIS JET TYPE HAS LIMITED GOLF CLUB SPACE
              </h5>
            </div>
          )}
          <h2>Passengers</h2>
          <TextLabelTile
            content={`${searchOptions.passengers} passengers ${
              searchOptions.petFriendly ? "● Pets on flight" : ""
            }`}
          />
          <h2>Itinerary</h2>
          {data?.itinerary?.legs.map((a, i) => (
            <TripCard
              key={i}
              {...a}
              total={data?.itinerary?.legs.length}
              jetType={data?.aircraftCategory}
            />
          ))}
          <h2>Add-Ons</h2>
          {data?.addonResponse?.map((a, i) => (
            <FlightAddOns onChange={onAddOnChange} {...a} key={i} />
          ))}
          <CarbonOffset />
          <h2>Cost</h2>
          <CostSummary
            reward={balance?.rewardPayApplicable}
            book={false}
            priceBreakup={data?.priceBreakup}
          />
          {/* <LightTextPanel
            title={"Weather Conditions"}
            body={
              "Inclimate weather conditions can significant schedule delays, airport closures and additional fees such as de-icing."
            }
            icon={
              <FaExclamationCircle size={"25"} color={ScssVariables.blue} />
            }
          />
          <LightTextPanel
            title={"International Travel"}
            body={
              "A US Customs stop may be required prior to reaching your final destination. Additional passenger info required; an AB1 concierge team member will facilitate for you."
            }
            icon={
              <FaExclamationCircle size={"25"} color={ScssVariables.blue} />
            }
          /> */}
          <h2 ref={scrollDiv} className="payment-section">
            Payment
          </h2>
          {/*<LightTextPanel
            title={"How to book this flight"}
            body={
              "Pay now for this flight using a credit card or your AB1 wallet. Or reserve this flight and price with a $1,000 hold on a credit card and complete payment by wire transfer within 24 hours."
            }
          />*/}
          {config.message && dateStatus && (
            <div className="peak-main">
              <div
                className={`peak-div${
                  !peakStatus && btnPeakStatus ? "-validation" : ""
                }`}
              >
                <div
                  className={`peak-div${
                    !peakStatus && btnPeakStatus
                      ? "-validation-style peak-div-style"
                      : "-style"
                  }`}
                >
                  <p className="peak-headline">Peak Travel Alert</p>
                  <div className="header">
                    <img className="icon" src={vector} alt="" />
                    <p className="body-font-p peak-msg">
                      Our Flight Concierge Team will reach-out to you to confirm
                      availability. Your itinerary price is guaranteed.
                    </p>
                    <div className="check-box">
                      <CheckBox
                        checked={false}
                        onClick={(checked) => {
                          setPeakStatus(!peakStatus);
                          // btnPeakStatusClick(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* {!peakStatus && btnPeakStatus && ( */}
              <span className="peak-validation">
                <p className="peak-validation-icon">*</p>
                <p className="peak-validation-msg">
                  By Clicking the above you acknowledge the current Peak Travel
                  Period to continue booking.
                </p>
              </span>
              {/* )} */}
            </div>
          )}
          <div className="peak-main">
            <div className="peak-div peak-trip">
              <div className="peak-div-style">
                <div className="header">
                  <p className="body-font-p peak-msg peak-trip-msg">
                    Due to rapidly fluctuating fuel prices, additional fuel surcharges could apply for trips booked more than 14 days in advance.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {!subPaymentOption && btnCheckboxStatus ? (
            <CompletePayment status={"false"} className="complete-payment" />
          ) : (
            <CompletePayment status={"true"} className="complete-payment" />
          )}
          <LightTextPanel
            title={"Weather Conditions"}
            body={
              "Inclimate weather conditions can significant schedule delays, airport closures and additional fees such as de-icing."
            }
            icon={
              <FaExclamationCircle size={"25"} color={ScssVariables.blue} />
            }
          />
          <LightTextPanel
            title={"International Travel"}
            body={
              "A US Customs stop may be required prior to reaching your final destination. Additional passenger info required; an AB1 concierge team member will facilitate for you."
            }
            icon={
              <FaExclamationCircle size={"25"} color={ScssVariables.blue} />
            }
          />
          {/* <div className="term-agreement-title">
            <p>Booking Terms</p>
          </div> */}
          <LightTextPanel
            title={"Booking Terms"}
            body={
              <div class="float-container">
                <div className="term-agreement">
                  <div class="float-child">
                    <div className="checkbox-panel">
                      <CheckBox
                        checked={false}
                        onClick={(checked) => {
                          setTerms(!terms);
                        }}
                      />
                    </div>
                  </div>
                  <div class="float-child">
                    <div className="terms">
                      <p>
                        I agree to the{" "}
                        <u>
                          <a target="_blank" href="/terms-of-service">
                            terms & conditions
                          </a>
                        </u>
                        ,{" "}
                        <u>
                          <a target="_blank" href="/terms-of-sale">
                            terms of sale
                          </a>
                        </u>
                        , and{" "}
                        <u>
                          <a target="_blank" href="/privacy-policy">
                            privacy policy
                          </a>
                        </u>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          {/* <div class="float-container">
            <div className="term-agreement">
              <div class="float-child">
                <div className="checkbox-panel">
                  <CheckBox
                    checked={false}
                    onClick={(checked) => {
                      setTerms(!terms);
                    }}
                  />
                </div>
              </div>
              <div class="float-child">
                <div className="terms">
                  <p>
                    I agree to the{" "}
                    <u>
                      <a target="_blank" href="/terms-of-service">
                        terms & conditions
                      </a>
                    </u>
                    ,{" "}
                    <u>
                      <a target="_blank" href="/terms-of-sale">
                        terms of sale
                      </a>
                    </u>
                    , and{" "}
                    <u>
                      <a target="_blank" href="/privacy-policy">
                        privacy policy
                      </a>
                    </u>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="terms">
        <CheckBox />
          <div className="label">
            <p className="title">I agree to the <u>terms and conditions</u>,<u>terms of sales</u> and <u>privacy policy</u></p>
            
          </div>
         
        </div> */}
          {subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY && (
            <Button
              iconPosition={"left"}
              icon={<FaLock />}
              // disabled={terms}
              label={"Confirm your Booking"}
              type={"yellow"}
              onClick={() => {
                if (!subPaymentOption) {
                  setCheckboxStatus(true);
                }
                if (!peakStatus && config.message) {
                  btnPeakStatusClick(true);
                  window.document
                    .querySelector(".payment-section")
                    .scrollIntoView({ behavior: "smooth" });
                }
                if ((peakStatus || !config.message) && subPaymentOption) {
                  if (
                    profile.phone === "" ||
                    profile.phone === null ||
                    profile.phone === undefined
                  ) {
                    setUpdateModal(true);
                  } else {
                    initPayment({
                      bookingId: id,
                      paymentMethod: subPaymentOption.replace(
                        /PAY_BY_WIRE_|PAY_NOW_/,
                        ""
                      ),
                      paymentMode: selectedPaymentOption,
                    });
                    // if (terms == false) {
                    setModal(true);
                    // }
                  }
                }
                // }
              }}
              fullWidth={true}
            />
          )}

          {subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD && (
            <Button
              iconPosition={"left"}
              icon={<FaLock />}
              // disabled={terms}
              label={"Confirm your Booking"}
              type={"yellow"}
              onClick={() => {
                if (!subPaymentOption) {
                  setCheckboxStatus(true);
                }
                if (!peakStatus && config.message) {
                  btnPeakStatusClick(true);
                  window.document
                    .querySelector(".payment-section")
                    .scrollIntoView({ behavior: "smooth" });
                }
                if ((peakStatus || !config.message) && subPaymentOption) {
                  if (
                    profile.phone === "" ||
                    profile.phone === null ||
                    profile.phone === undefined
                  ) {
                    setUpdateModal(true);
                  } else {
                    initPayment({
                      bookingId: id,
                      paymentMethod: subPaymentOption.replace(
                        /PAY_BY_WIRE_|PAY_NOW_/,
                        ""
                      ),
                      paymentMode: selectedPaymentOption,
                    });
                    // if (terms == false) {
                    setModal(true);
                    // }
                  }
                }
                // }
              }}
              fullWidth={true}
            />
          )}

          {subPaymentOption === "" && (
            <Button
              iconPosition={"left"}
              icon={<FaLock />}
              label={"Reserve this flight"}
              type={"yellow"}
              onClick={() => {
                scrollSmoothHandler();
                if (!subPaymentOption) {
                  // btnPeakStatusClick(false);
                  setCheckboxStatus(true);
                }
                // else {
                // setCheckboxStatus(false);
                if (!peakStatus && config.message) {
                  btnPeakStatusClick(true);
                  window.document
                    .querySelector(".payment-section")
                    .scrollIntoView({ behavior: "smooth" });
                }
                // }
              }}
              fullWidth={true}
            />
          )}

          {subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD && (
            <Button
              iconPosition={"left"}
              icon={<FaLock />}
              // disabled={terms}
              label={"Reserve this Flight"}
              type={"yellow"}
              onClick={() => {
                if (!subPaymentOption) {
                  // btnPeakStatusClick(false);
                  setCheckboxStatus(true);
                }
                //  else {
                //   setCheckboxStatus(false);
                if (!peakStatus && config.message) {
                  btnPeakStatusClick(true);
                  window.document
                    .querySelector(".payment-section")
                    .scrollIntoView({ behavior: "smooth" });
                }
                if (subPaymentOption && (peakStatus || !config.message)) {
                  if (
                    profile.phone === "" ||
                    profile.phone === null ||
                    profile.phone === undefined
                  ) {
                    setUpdateModal(true);
                  } else {
                    initPayment({
                      bookingId: id,
                      paymentMode: PAYMENT_TYPE_PAY_BY_WIRE,
                    });

                    // if (terms == false) {
                    // setModal(true);
                    history.push("/payment-success", {
                      // finalCall,
                      // amountDue: data?.priceBreakup?.totalPrice,
                      // data,
                      // subType: subPaymentOption.replace(/PAY_BY_WIRE_|PAY_NOW_/, ""),
                      type: PAYMENT_TYPE_PAY_BY_WIRE,
                    });
                    // }
                  }
                }
                // }
              }}
              fullWidth={true}
            />
          )}

          {subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY && (
            <Button
              iconPosition={"left"}
              icon={<FaLock />}
              // disabled={terms}
              label={"Confirm your Booking"}
              type={"yellow"}
              onClick={() => {
                if (!subPaymentOption) {
                  // btnPeakStatusClick(false);
                  setCheckboxStatus(true);
                }
                //  else {
                //   setCheckboxStatus(false);
                if (!peakStatus && config.message) {
                  btnPeakStatusClick(true);
                  window.document
                    .querySelector(".payment-section")
                    .scrollIntoView({ behavior: "smooth" });
                }
                if (subPaymentOption && (peakStatus || !config.message)) {
                  if (
                    profile.phone === "" ||
                    profile.phone === null ||
                    profile.phone === undefined
                  ) {
                    setUpdateModal(true);
                  } else {
                    initPayment({
                      bookingId: id,
                      paymentMode: selectedPaymentOption,
                    });

                    let { paymentId } = initiatePayment;
                    onApplePayInitiated(paymentId, history, data);
                    //setModal(true);
                  }
                }
                // }
              }}
              fullWidth={true}
            />
          )}
        </div>
        <Modal close={() => setModal(false)} show={modal}>
          <div className="complete-payment-modal">
            {subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY && (
              <h1>Complete Payment</h1>
            )}
            {subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD && (
              <h1>Complete Payment</h1>
            )}
            {subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_CREDIT_CARD && (
              <h1 className="reserve-title">Reserve Booking Success !</h1>
            )}
            {subPaymentOption.includes("CREDIT_CARD") &&
              subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD && (
                <p className="light-text">
                  The full cost of this flight will be charged to your credit
                  card, including of a 3% fee.
                </p>
              )}
            {/*PLACEHOLDER FOR RESERVE*/}
            {/* {subPaymentOption.includes("CREDIT_CARD") &&
              selectedPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE && (
                <p className="light-text" />
              )} */}
            {subPaymentOption.includes("CREDIT_CARD") &&
              subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD && (
                <CreditCardCharges {...(initiatePayment?.flightData ?? {})} />
              )}
            {subPaymentOption.includes("WALLET_PAY") && (
              <WalletPayBreakDown
                balance={balance?.walletBalance}
                total={data?.priceBreakup?.totalPrice}
                rewards={data?.priceBreakup?.rewardsInfo}
              />
            )}
            {subPaymentOption.includes("CREDIT_CARD") && (
              <>
                {/* {selectedPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE &&
                  <>
                    <h6 className="reserve-heading">Reserve Flight for $1000</h6>
                    <p className="light-text">
                      We'll place a $1,000 hold on this credit card. This will
                      automatically be released after you complete a wire transfer
                      for this flight, or if payment is not received within 24
                      hours.
                    </p>
                  </>
                } */}
                <CreditCardDetails
                  reset={ccReset}
                  setReset={setReset}
                  data={ccPay}
                  setData={setCcPay}
                />
              </>
            )}
            {subPaymentOption.includes("APPLE_PAY") && (
              <>
                <h6 className="reserve-heading">Reserve this Flight </h6>
                {/*  <p className="light-text">
                  We'll place a $1,000 hold on this credit card. This will
                  automatically be released after you complete a wire transfer
                  for this flight, or if payment is not received within 24
                  hours.
                </p> */}
              </>
            )}
            {subPaymentOption === PAYMENT_TYPE_PAY_BY_WIRE_WALLET_PAY && (
              <Button
                iconPosition={"left"}
                // disabled={terms}
                icon={<FaLock />}
                onClick={async () => {
                  if (subPaymentOption.includes("CREDIT_CARD")) {
                    let isValid = validateCCDetails(ccPay);
                    if (isValid) {
                      setLoader(true);
                      let { apiLoginId, clientKey } =
                        initiatePayment?.authorizeNetData;
                      let authData = await authenticationToken(
                        clientKey,
                        apiLoginId,
                        ccPay
                      );
                      await sendFinalPaymentCall(authData);
                    } else {
                      toast.error("Credit card Details are not valid!");
                    }
                  } else if (subPaymentOption.includes("WALLET_PAY")) {
                    await makeWalletPayCall();
                  } else if (subPaymentOption.includes("APPLE_PAY")) {
                    console.log("INITIATED APPLE PAY!");
                    let { paymentId } = initiatePayment;
                    console.log(paymentId);
                    onApplePayInitiated(paymentId, history, data);
                  }
                }}
                label={
                  // selectedPaymentOption === PAYMENT_TYPE_PAY_NOW
                  //   ?
                  // "Complete Your booking"
                  "Reserve this flight"
                }
                type={"yellow"}
                style={{ cursor: "none" }}
                fullWidth={true}
              />
            )}
            {/* <div className="term-agreement-title">
              <p>Booking Terms</p>
            </div> */}
            {/* <div className="term-agreement"> */}
            {/* <div className="checkbox-panel">
                <CheckBox
                  checked={false}
                  onClick={(checked) => {
                    setTerms(!terms);
                  }}
                />
              </div> */}
            {/* <div className="terms">
                <p>
                  I agree to the{" "}
                  <a target="_blank" href="/terms-of-service">
                    terms & conditions
                  </a>
                  ,{" "}
                  <a target="_blank" href="/terms-of-sale">
                    terms of sale
                  </a>
                  , and{" "}
                  <a target="_blank" href="/privacy-policy">
                    privacy policy
                  </a>
                  .
                </p>
              </div> */}
            {/* </div> */}
            {subPaymentOption === PAYMENT_TYPE_PAY_NOW_WALLET_PAY && (
              <Button
                iconPosition={"left"}
                // disabled={terms}
                icon={<FaLock />}
                onClick={async () => {
                  if (subPaymentOption.includes("CREDIT_CARD")) {
                    let isValid = validateCCDetails(ccPay);
                    if (isValid) {
                      setLoader(true);
                      let { apiLoginId, clientKey } =
                        initiatePayment?.authorizeNetData;
                      let authData = await authenticationToken(
                        clientKey,
                        apiLoginId,
                        ccPay
                      );
                      await sendFinalPaymentCall(authData);
                    } else {
                      toast.error("Credit card Details are not valid!");
                    }
                  } else if (subPaymentOption.includes("WALLET_PAY")) {
                    await makeWalletPayCall();
                  } else if (subPaymentOption.includes("APPLE_PAY")) {
                    console.log("INITIATED APPLE PAY!");
                    let { paymentId } = initiatePayment;
                    console.log(paymentId);
                    onApplePayInitiated(paymentId, history, data);
                  }
                }}
                label={
                  // selectedPaymentOption === PAYMENT_TYPE_PAY_NOW
                  //   ?
                  "Complete Your booking"
                  // : "Reserve Your Booking"
                }
                type={"yellow"}
                style={{ cursor: "none" }}
                fullWidth={true}
              />
            )}
            {subPaymentOption === PAYMENT_TYPE_PAY_NOW_CREDIT_CARD && (
              <Button
                iconPosition={"left"}
                // disabled={terms}
                icon={<FaLock />}
                onClick={async () => {
                  if (subPaymentOption.includes("CREDIT_CARD")) {
                    let isValid = validateCCDetails(ccPay);
                    if (isValid) {
                      setLoader(true);
                      let { apiLoginId, clientKey } =
                        initiatePayment?.authorizeNetData;
                      let authData = await authenticationToken(
                        clientKey,
                        apiLoginId,
                        ccPay
                      );
                      await sendFinalPaymentCall(authData);
                    } else {
                      toast.error("Credit card Details are not valid!");
                    }
                  } else if (subPaymentOption.includes("WALLET_PAY")) {
                    await makeWalletPayCall();
                  } else if (subPaymentOption.includes("APPLE_PAY")) {
                    console.log("INITIATED APPLE PAY!");
                    let { paymentId } = initiatePayment;
                    console.log(paymentId);
                    onApplePayInitiated(paymentId, history, data);
                  }
                }}
                label={
                  // selectedPaymentOption === PAYMENT_TYPE_PAY_NOW
                  //   ?
                  "Complete Your booking"
                  // : "Reserve Your Booking"
                }
                type={"yellow"}
                style={{ cursor: "none" }}
                fullWidth={true}
              />
            )}
          </div>
        </Modal>
        <LoadingOverlay
          active={loader}
          spinner
          text="Loading your content..."
        />
      </div>
      <UpdatePersonalInfoModal
        profile={profile}
        setProfile={setProfile}
        status={updateModal}
        onChange={setUpdateModal}
        initiatePaymentAfterUpdate={initiatePaymentAfterUpdate}
      />
    </PageContainer>
  );
}

function mapStateToProps({ aircraft, payment }) {
  return {
    selectedAircraft: aircraft.selectedAircraft,
    selectedAddOns: aircraft.selectedAddOns,
    selectedPaymentOption: payment.paymentOption,
    subPaymentOption: payment.subPaymentOption,
    initiatePayment: payment.initiatePayment,
    balance: aircraft.balance,
    jetInfo: aircraft.jetInfo.data,
    searchOptions: aircraft.searchOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedAircraft: (data) =>
      dispatch({ type: SET_SELECTED_AIRCRAFT, payload: data }),
    setSelectedAddOns: (data) =>
      dispatch({ type: SET_SELECTED_ADDONS, payload: data }),
    updateAddOns: (data) => dispatch({ type: UPDATE_ADDONS, payload: data }),
    initPayment: (data) => dispatch({ type: INIT_PAYMENT, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBooking);
