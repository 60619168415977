import React, { useEffect, useState } from "react";
import ab1Logo from "../../assets/logos/ab1_logo.png";
import ItineraryLeg from "../../components/ItineraryLeg";
import { useParams } from "react-router-dom";
import { API_GET_TRIP_ITINERARY_DETAILS } from "../../middleware/constants/url.constants";
import { GET_AUTH } from "../../utils/HTTP.utils";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import LoadingOverlay from "react-loading-overlay";
import { PDFDownloadLink, Document } from "@react-pdf/renderer";
import LegPDF from "../../components/PDFRenderer/LegPDF";
import FrontPage from "../../components/PDFRenderer/FrontPage";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import "./Itinerary.scss";

const MyDocument = ({ data, tailNumber, lastStop }) => {
  return (
    <Document>
      <FrontPage data={data} lastStop={lastStop} />
      {data.legs?.map((leg, i) => {
        return (
          <LegPDF
            data={leg}
            status={data.leadPassenger}
            tailNumber={tailNumber}
            key={i}
          />
        );
      })}
    </Document>
  );
};

const Itinerary = ({ }) => {
  var { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  const [leadPassenger, setLeadPassenger] = useState({});
  const [tailNumber, setTailNumber] = useState("");

  function initCamelize(str) {
    const strOrgin = [];
    if (str) {
      const strVal = str.toLowerCase(str);
      const strArray = strVal.split(' ');
      for (let i = 0; i < strArray.length; i++) {
        if (strArray[i]) {
          strOrgin.push(strArray[i].trim());
        }
      }
      return strOrgin
        .map(a => a.trim())
        .map(a => a[0].toUpperCase() + a.substring(1))
        .join("  ")
    } else {
      return 'N/A';
    }

  }

  useEffect(() => {
    getIsUaAuthentication().then(({ token }) => {
      GET_AUTH(API_GET_TRIP_ITINERARY_DETAILS, token, {
        "booking-id": id,
      }).then((data) => {
        data?.legs?.sort(function (a, b) {
          var keyA = a.legOrder,
            keyB = b.legOrder;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        setData(data);
        setLoader(false);
        setLeadPassenger(data.leadPassenger);

        let tail = [];
        var tailNumbers = "";
        var tails;
        let tailNumber = data.legs;

        tailNumber.forEach(function (item) {
          if (item.tailNumber !== "" && item.tailNumber !== null) {
            tail.push(item.tailNumber);
          } else {
            tailNumbers = "N/A";
          }
        });
        tailNumber.forEach((item) => {
          if (item.tailNumber !== "") {
            tails = item.tailNumber;
          } else {
            tails = "N/A";
          }
        });

        if (tail[0] === null) {
          tailNumbers = tails;
        } else {
          tailNumbers = tail.shift();
        }

        setTailNumber(tailNumbers);
        var legDetails = data.legs;
        for (let j = data.legs.length - 1; j >= 0; j--) {
          var item = data.legs[j];
          var crewSettings = item.crewSettings;
          if (item.legOrder !== 1) {
            if (crewSettings === true || crewSettings === null) {
              var tempCrews = [];
              for (let i = item.legOrder - 2; i >= 0; i--) {
                if (data.legs[i] && data.legs[i]["crews"].length > 0) {
                  if (
                    data.legs[i]["crews"][0].name ||
                    data.legs[i]["crews"][1].name
                  ) {
                    tempCrews = legDetails[i]["crews"];
                    break;
                  }
                }
              }
              data.legs[item.legOrder - 1]["crews"] = tempCrews;
            }
          }
        }
      });
    });
  }, []);

  return (
    <div className="Itinerary">
      <div className="header-container">
        <div className="trip-header">
          <div>
            <h1 className="bookingid">Trip Itinerary #{data.bookingId}</h1>
            <div className="legs-heading">
              {data.legs?.map((leg, i) => {
                return (
                  <h4 key={i} className="legs">
                    {leg.departureAirport?.icaoCode} -&nbsp;
                  </h4>
                );
              })}
              <h4 className="legs">
                {!loader
                  ? data.legs[data.legs.length - 1]?.arrivalAirport?.icaoCode
                  : ""}
              </h4>
            </div>
          </div>
          <img src={ab1Logo} className="logo" alt="Airbook One" />
        </div>
      </div>
      <div className="basic-info">
        <p className="info-subheading">AIRBOOK ONE Contact</p>
        <div className="info-box">
          <div className="row">
            <p className="key">Phone</p>
            <a href={"tel:" + data.ab1Contact?.phone}>
              {data.ab1Contact?.phone}
            </a>
          </div>
          <div className="row">
            <p className="key">Email</p>
            <a href={"mailto:" + data?.ab1Contact?.email}>
              {data?.ab1Contact?.email}
            </a>
          </div>
        </div>
        <p className="info-subheading">Customer Information</p>
        <div className="info-box custInfo">
          <div className="row">
            <p className="key">Name</p>
            <p>{initCamelize(data.customerContact?.name)}</p>
          </div>
          <div className="row">
            <p className="key">Phone</p>
            <a href={"tel:" + data.customerContact?.phone}>
              {data.customerContact?.phone || "N/A"}
            </a>
          </div>
          <div className="row">
            <p className="key">Email</p>
            <a href={"mailto:" + data.customerContact?.email}>
              {data.customerContact?.email || "N/A"}
            </a>
          </div>
        </div>
      </div>
      {data.legs?.map((leg, i) => {
        return (
          <ItineraryLeg
            key={i}
            data={leg}
            status={data.leadPassenger}
            tailNumber={tailNumber}
          />
        );
      })}
      <div className="end">
        <p>
          To add additional services contact our
          <a href="/concierge"> Flight Concierge Team.</a>
        </p>
      </div>
      <div className="download">
        <PDFDownloadLink
          document={
            <MyDocument
              data={data}
              tailNumber={tailNumber}
              lastStop={
                !loader
                  ? data.legs[data.legs.length - 1]?.arrivalAirport?.icaoCode
                  : null
              }
            />
          }
          fileName={"itinerary-" + id + ".pdf"}
        >
          {({ blob, url, loading, error }) => {
            if (error) {
              toast.error("Something Went Wrong");
            } else {
              return (
                <Button
                  label={"Download"}
                  disabled={loading ? true : false}
                  fullWidth={false}
                  size={"large"}
                />
              );
            }
          }}
        </PDFDownloadLink>
      </div>
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    </div>
  );
};

export default Itinerary;
