import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Button from "../../components/Button";
import FaApple from "../../assets/icons/apple-btn.png";
import FaGoogle from "../../assets/icons/google-btn.png";
import FaFacebook from "../../assets/icons/fb-btn.png";
import "./Login.scss";

function SocialMediaLinks({ value, fieldValue }) {
  const SignIn = async (provider) => {
    console.log(provider)
    if (value === "Register") {
      localStorage.setItem("fname", fieldValue.firstName);
      localStorage.setItem("lname", fieldValue.lastName);
      localStorage.setItem("email", fieldValue.email);
      localStorage.setItem("phone", fieldValue.phoneNumber);
      localStorage.setItem("telCode", fieldValue.telCode);
      

      try {
        const user = await Auth.federatedSignIn({ provider });
      } catch (error) {
        // const firstname = localStorage?.getItem("fname");
        // const lastname = localStorage?.getItem("lname");
        // const email = localStorage?.getItem("email");
        // const ph_no = localStorage?.getItem("phone");
        // const telCode = localStorage?.getItem("telCode");
        // const phone = '+1' + ph_no;
        // if (firstname && firstname !== "null" && firstname !== "") {
        //   getIsUaAuthentication()
        //     .then(({ token }) => {
    
        //       POST_AUTH(
        //         API_PROFILE,
        //         {
        //           firstname,
        //           lastname,
        //           email,
        //           phone,
        //         },
        //         token
        //       );
        //     })
        //     .then(() => {
        //       localStorage.setItem("fname", null);
        //       localStorage.setItem("lname", null);
        //       localStorage.setItem("email", null);
        //       localStorage.setItem("phone", null);
        //     });
        // }
        //console.error(error);
      }
    } else {
      localStorage.setItem("socialLogin", "yes");
      const data = await Auth.federatedSignIn({ provider }).then((value) => {
        console.log("value", value);
      });
      console.log(data);

      return false;
    }
  };

  return (
    <div className="social-login-icon-links">
      <div style={{ display: 'flex', alignItems: 'center' , marginBottom:'12%', marginLeft:'15%', width: '70%'}}>
    <hr style={{ flex: 1, margin: '0 5px', borderTop: '1px solid #2C3034'}} />
    <span style={{ margin: '0 5px' }}>Or</span>
    <hr style={{ flex: 1, margin: '0 5px', borderTop: '1px solid #2C3034' }} />
  </div>

      
      <p> {value} With:</p>
      <div className={"social-login-container"}>
        <Button
          fullWidth={false}
          onClick={() => SignIn("SignInWithApple")}
          size={"social"}
          img={FaApple}
        />
        <Button
          fullWidth={false}
          onClick={() => SignIn("Google")}
          img={FaGoogle}
          size={"social"}
        />
        <Button
          fullWidth={false}
          onClick={() => SignIn("Facebook")}
          img={FaFacebook}
          size={"social"}
        />
      </div>
    </div>
  );
}

export default SocialMediaLinks;
