import React, { useEffect, useState} from "react";
import { Auth } from "aws-amplify";
import { GET_AUTH } from "./../utils/HTTP.utils";
import { API_PROFILE } from "./../middleware/constants/url.constants";
import { useSelector } from "react-redux"; // Importing useSelector from React-Redux

function useAuthentication() {
  const [authenticated, setAuthenticated] = useState({
    status: false,
    token: null,
    createAccount: false,
    userData:"",
  });
  const fetchData = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      let data = await GET_AUTH(
        API_PROFILE,
        user.signInUserSession.accessToken.jwtToken
      );

      if (
        data.email !== null &&
        data.email !== "" &&
        data.firstname !== null &&
        data.firstname !== "" &&
        data.lastname !== null &&
        data.lastname !== "" &&
        data.phone !== null &&
        data.phone !== ""
      ) {
        setAuthenticated({
          status: true,
          token: user.signInUserSession.accessToken.jwtToken,
        });
      } else {
        setAuthenticated({
          status: false,
          token: null,
        });
      }
    } catch (e) {
      setAuthenticated({
        status: false,
        token: null,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getIsUaAuthentication = async (userData) => {

    const socialLogin = localStorage.getItem("socialLogin") || "no";
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (user) {
        let data;
        if(socialLogin && socialLogin === "yes") {
          if(userData && userData.hasOwnProperty("email")) {
            data = userData;
          }
          else {
              data = await GET_AUTH(
              API_PROFILE,
              user.signInUserSession.accessToken.jwtToken
            );
          }
          if (
            data &&
            data.email !== null &&
            data.email !== "" &&
            data.firstname !== null &&
            data.firstname !== "" &&
            data.lastname !== null &&
            data.lastname !== "" &&
            data.phone !== null &&
            data.phone !== ""
          ) {
            return {
              status: true,
              token: user.signInUserSession.accessToken.jwtToken,
              user,
              createAccount: false,
              userData: data,
            };
          } else {
              return { status: false, token: null, createAccount : true, socialLogin : true ,userData: data };
          }

        }
        else {
          return {
            status: true,
            token: user.signInUserSession.accessToken.jwtToken,
            user,
            createAccount : false, 
            socialLogin : false,
            userData: userData
          };
        }
        
       
      }
      else{
        return { status: false, token: null, createAccount : false, socialLogin : false ,userData: userData };

      }
  
  
      // return {
      //   status: true,
      //   token: user.signInUserSession.accessToken.jwtToken,
      //   user,
      // };
    } catch (error) {
      return { status: false, token: null };
    }
  };

  return {authenticated, getIsUaAuthentication};
}

export default useAuthentication;