import React from "react";
import PlaneIcon from "../../assets/icons/planeicon.png";
import { useHistory } from "react-router-dom";
import "./PopularTrips.scss";
import { Link } from "react-router-dom";
import moreRoutes from "./data.js";
import { BsArrowRight } from "react-icons/bs";
import ArrowDown from "../../assets/icons/arrow-down.png";

function PopularTripCards() {
  const history = useHistory();
  let routes = [
    {
      Destination: "Teterboro to West Palm Beach",
      deptICAO: "KTEB",
      arrivalICAO: "KPBI",
    },
    {
      Destination: "Chicago to Phoenix",
      deptICAO: "KMDW",
      arrivalICAO: "KPHX",
    },
    {
      Destination: "Austin to Teterboro",
      deptICAO: "KAUS",
      arrivalICAO: "KTEB",
    },
    {
      Destination: "Los Angeles to Teterboro",
      deptICAO: "KVNY",
      arrivalICAO: "KTEB",
    },
    // {Destination:"Nashville to Chicago", deptICAO:"KBNA", arrivalICAO:"KMDW"},
    // {Destination:"Teterboro to Washington DC", deptICAO:"KTEB", arrivalICAO:"KIAD"},
    // {Destination:"Chicago to West Palm Beach", deptICAO:"KMDW", arrivalICAO:"KPBI"},
    // {Destination:"Las Vegas to Teterboro", deptICAO:"KLAS", arrivalICAO:"KTEB"},
    // {Destination:"Phoenix to Chicago", deptICAO:"KPHX", arrivalICAO:"KMDW"},
    // {Destination:"West Palm Beach to Teterboro", deptICAO:"KPBI", arrivalICAO:"KTEB"},
    // {Destination:"Los Angeles to Washington DC", deptICAO:"KVNY", arrivalICAO:"KIAD"},
    // {Destination:"Austin to Chicago", deptICAO:"KAUS", arrivalICAO:"KMDW"}
  ];
  return (
    // <div></div>
    <div className="PopularTrips">
      <div className="PopularContainer">
        <h1 className="Title" style={{ marginBottom: "45px" , fontSize: "48px" }}>
          Popular Routes
        </h1>
        <div className="TopSection">
          {/* <div className="PopularCard-1 Cloud1 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KTEB", arrivalICAO:"KPBI"}})}}>
                <p className="routeName">Teterboro to West Palm Beach</p>
                <div className="CodeSection">
                    <p>KTEB</p>
                    <img src={PlaneIcon}/>
                    <p>KPBI</p>
                </div>
            </div> */}
          {/* <div className="PopularCardContainer">
                <div className="PopularCard-2 Cloud2 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KMDW", arrivalICAO:"KPHX"}})}}>
                    <p className="routeName">Chicago to Phoenix</p>
                    <div className="CodeSection">
                        <p>KMDW</p>
                        <img src={PlaneIcon}/>
                        <p>KPHX
</p>
                    </div>
                </div>
                <div className="PopularCard-2 Cloud3 TopPicture" onClick={() => {history.push("/popular-routes-book",{"route":{deptICAO:"KAUS", arrivalICAO:"KTEB"}})}}>
                    <p className="routeName">Austin to Teterboro</p>
                    <div className="CodeSection">
                        <p>KAUS</p>
                        <img src={PlaneIcon}/>
                        <p>KTEB</p>
                    </div>
                </div>
            </div> */}
          <div className="PopularCardGrid">
            {routes.map((route, i) => {
              return (
                <div
                  index={i}
                  className={
                    "PopularCard-3 BottomPicture Cloud" + (((i + 1) % 6) + 1)
                  }
                  onClick={() => {
                    history.push("/popular-routes-book", { route: route });
                  }}
                >
                  <div className="inner-content">
                    <p className="routeName">{route.Destination}</p>
                    <div className="CodeSection">
                      <p>{route.deptICAO}</p>
                      <img src={PlaneIcon} />
                      <p>{route.arrivalICAO}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div class="button-div">
            <Link to="/popular-routes">
              <button class="link-button">View All Routes</button>
            </Link>
          </div>
          {/* <PopularTripCards/> */}
        </div>
      </div>
    </div>
  );
}

export default PopularTripCards;
