import React, { useEffect, useState} from "react";
import { Auth } from "aws-amplify";
import { GET_AUTH } from "./../utils/HTTP.utils";
import { API_PROFILE } from "./../middleware/constants/url.constants";
import { useSelector } from "react-redux"; // Importing useSelector from React-Redux

function useAuthentication() {
  const [authenticated, setAuthenticated] = useState({
    status: false,
    token: null,
    createAccount: false,
    userData:"",
  });
  const fetchData = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();

      let data = await GET_AUTH(
        API_PROFILE,
        user.signInUserSession.accessToken.jwtToken
      );

      if (
        data.email !== null &&
        data.email !== "" &&
        data.firstname !== null &&
        data.firstname !== "" &&
        data.lastname !== null &&
        data.lastname !== "" &&
        data.phone !== null &&
        data.phone !== ""
      ) {
        setAuthenticated({
          status: true,
          token: user.signInUserSession.accessToken.jwtToken,
        });
      } else {
        setAuthenticated({
          status: false,
          token: null,
        });
      }
    } catch (e) {
      setAuthenticated({
        status: false,
        token: null,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return authenticated;
}

export default useAuthentication;
export const getIsUaAuthentication = async () => {
  try {
    let user = await Auth.currentAuthenticatedUser();

    return {
      status: true,
      token: user.signInUserSession.accessToken.jwtToken,
      user,
    };
  } catch (error) {
    return { status: false, token: null };
  }
};