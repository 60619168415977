import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaHeadset } from "react-icons/fa";
import "./CostSummary.scss";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import { UPDATE_ADDONS } from "../../middleware/constants/aircarft.constants";
import { connect } from "react-redux";
import CheckBox from "../CheckBox/CheckBox";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import ConciergeModal from "../../components/ConciergeModal";
import { MdEventNote } from "react-icons/md";
import Headset from "./../../assets/icons/talkToConcierge/headset.png";
import Modal from "../../components/Modal";
import { FaInfoCircle } from "react-icons/fa";
import ScssVariables from "../../styles/_export.module.scss";
import moment from "moment";
function CostSummary({
  disabled = false,
  book = true,
  priceBreakup = {},
  selectedAircraft,
  updateAddOns,
  reward,
  balance,
  addonIds,
}) {
  let history = useHistory();
  const [rewards, setRewards] = useState(false);
  const [wait, setWait] = useState(false);
  const [conciergeModal, setConciergeModal] = useState(false);
  let [modalRecommended, setModalRecommended] = useState(false);
  const [conciergeStatus, setConciergeStatus] = useState(false);
  const handleRewards = (checked) => {
    let selectedAddOnId = [];
    if (selectedAircraft.addonResponse) {
      selectedAircraft.addonResponse.forEach(function (item) {
        if (item.addonList) {
          item.addonList.forEach(function (addOn) {
            if (addOn.selected) {
              selectedAddOnId.push(addOn.addOnId)
            }
          })
        }
      });
    }

    updateAddOns({
      addonIds: selectedAddOnId,
      aboneSearchId: selectedAircraft.aboneSearchId,
      useRewards: checked
    });
  };

  useEffect(() => {
    if (selectedAircraft && selectedAircraft.itinerary && selectedAircraft.itinerary.departureDate) {
      const depDate = moment(
        selectedAircraft.itinerary.departureDate,
        "DD-MM-YYYY hh:mm:ss"
      ).format("YYYY-MM-DD");
      const currentDate = new Date()
      const currentAddDate = new Date(currentDate.setDate(currentDate.getDate() + 45));
      const currentFormDate = moment(
        currentAddDate,
        "dddd DD/MM/YYYY hh:mm A"
      ).format("YYYY-MM-DD");
      const cDate = Date.parse(new Date(currentFormDate.replace(/-/g, "/")));
      const dDate = Date.parse(new Date(depDate.replace(/-/g, "/")));
      if (dDate >= cDate) {
        setConciergeStatus(true);
      } else {
        setConciergeStatus(false);
      }
    }
  })

  useEffect(() => {
    if (selectedAircraft.bookingId && wait && !disabled)
      history.push(`/review-booking/${selectedAircraft.bookingId}`);
  }, [selectedAircraft.bookingId]);

  return (
    <div className="CostSummary">
      <div className="summery">
        <div className={disabled ? "header-disabled header" : "header"}>
          <div className="total-container">
            {/* <p className="title">Total&nbsp;</p><p className="sub-title">(incl FET)</p>   
            <p className="value">{disabled?"----":"$"+priceBreakup.totalPrice?.toLocaleString()}</p>             */}
            <h4 className="subtitile-h4">Your Trip</h4>
            <h4 className="value subtitile-h4">
              {disabled
                ? "----"
                : "$" + priceBreakup?.aircraftPrice?.toLocaleString()}
            </h4>
          </div>
          {!disabled && (
            <p className="body-font-p">
              <AiFillDollarCircle size={14} /> You will earn $
              {priceBreakup.rewardsInfo?.toLocaleString()} in cash rewards
            </p>
          )}
        </div>
        <div className="body">
          {/* <div className="detail-container TripPriceContainer">
            <div>
              <p className="title">Your Trip</p>
              <p className="value">{disabled?"----":"$"+priceBreakup?.aircraftPrice?.toLocaleString()}</p>
            </div>
            {!disabled && 
              <p className="sub-title"><AiFillDollarCircle size={16}/> You will earn
                ${priceBreakup.rewardsInfo?.toLocaleString()} in cash
                rewards</p>
            }
          </div> */}
          {/*<div className="detail-container">*/}
          {/*  <p className="title">Insurance</p>*/}
          {/*  <p className="value">${0}</p>*/}
          {/*</div>*/}
          <div className="detail-container">
            <h5 className="subtitile-h5">Taxes</h5>
            <h5 className="value subtitile-h5">
              {disabled ? "----" : "$" + priceBreakup?.taxes?.toLocaleString()}
            </h5>
          </div>
          <div className="detail-container-total TripPriceContainer">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h5 className="subtitile-h5">Total</h5>
              <h5 className="value subtitile-h5">
                {disabled
                  ? "----"
                  : "$" + priceBreakup.totalPrice?.toLocaleString()}
              </h5>
            </div>
          </div>

          {/*{balance?.rewardPayApplicable && priceBreakup?.rewards > 0 && (
            <div className="detail-container">
              <h5 className="subtitile-h5">Rewards Used</h5>
              <h5 className="value subtitile-h5">
                (
                {disabled
                  ? "----"
                  : "$" + priceBreakup?.rewards?.toLocaleString()}
                )
              </h5>
            </div>
          )}*/}
          {book && !conciergeStatus && (
            <Button
              label={"Book Now"}
              disabled={disabled || conciergeStatus}
              type={disabled || conciergeStatus ? "dark-grey" : "yellow"}
              onClick={async () => {
                // let { status: isAuth } = await getIsUaAuthentication();
                // if (isAuth) {
                  updateAddOns({
                    addonIds: addonIds,
                    aboneSearchId: selectedAircraft.aboneSearchId,
                    useRewards: rewards
                  });
                  setWait(true);
                  if (selectedAircraft.bookingId) {
                    history.push(
                      `/review-booking/${selectedAircraft.bookingId}`
                    );
                  }
                // } else {
                //   history.push("/login", { review: true });
                // }
              }}
            />
          )}
        {!conciergeStatus && (
            <div className="detail-price-guaranteed TripPriceContainer">
              <div>
                <p
                  className="title"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Trip Price Guaranteed
              </p>
                <p
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    marginTop: "10px",
                  }}
                >
                  Based on actual aircraft availability
            </p>
                <p
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    marginBottom: "32px",
                  }}
                >
                  Flight Concierge Team available 24/7.
            </p>
              </div>
              <Button
                label={`Talk to Concierge`}
                onClick={() => {
                  setConciergeModal(true);
                }}
                type={"blue"}
                icon={
                  <img
                    src={Headset}
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                }
                iconPosition="left"
              />

              {conciergeModal && (
                <ConciergeModal
                  status={conciergeModal}
                  // existing={state}
                  // onNewTraveller={onNewTravellerSelect}
                  onChange={setConciergeModal}
                />
              )}

              <div className="fuel-msg-div">
                <span>
                  <FaInfoCircle size={"13"} className="fuel-msg"
                    style={{
                      color: "#3881ef"
                    }} />
                </span>
                <a
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    paddingTop: "6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#3881ef",
                    textDecorationColor: " #3881ef"
                  }} onClick={() => setModalRecommended(true)}>
                  Additional Fuel Surcharges, may apply
                </a>
              </div>
              <Modal
                close={() => setModalRecommended(false)}
                show={modalRecommended}
              >
                <div className="good-deal-modal">
                  <p className="info-text-recommended">
                    Based on surging fuel prices across the globe, AIRBOOK ONE is monitoring
                    market prices for jet fuel, as we are committed to making these possible
                    additional surcharges as limited as possible.
                </p>
                </div>
              </Modal>
            </div>
          )}
          {conciergeStatus && (
            <div className="flight-concierge TripPriceContainer">
              <div style={{ width: "102%"}}>
                <p
                  className="title"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  Click Below for Flight Concierge
                </p>
                <p
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  For departures 45 days or more from today,
                 </p>
                <p
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    marginBottom: "32px",
                  }}
                >
                  click below to contact our Flight Concierge.
                 </p>
              </div>
              <Button
                label={`Talk to Concierge`}
                onClick={() => {
                  setConciergeModal(true);
                }}
                type={"blue"}
                icon={
                  <img
                    src={Headset}
                    style={{ marginRight: "8px", height: "20px" }}
                  />
                }
                iconPosition="left"
              />

              {conciergeModal && (
                <ConciergeModal
                  status={conciergeModal}
                  // existing={state}
                  // onNewTraveller={onNewTravellerSelect}
                  onChange={setConciergeModal}
                />
              )}

              <div className="fuel-msg-div">
                <span>
                  <FaInfoCircle size={"13"} className="fuel-msg"
                    style={{
                      color: "#3881ef"
                    }} />
                </span>
                <a
                  className="title"
                  style={{
                    textAlign: "center",
                    fontSize: "13px",
                    paddingTop: "6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#3881ef",
                    textDecorationColor: " #3881ef"
                  }} onClick={() => setModalRecommended(true)}>
                  Additional Fuel Surcharges, may apply
                </a>
              </div>
              <Modal
                close={() => setModalRecommended(false)}
                show={modalRecommended}
              >
                <div className="good-deal-modal">
                  <p className="info-text-recommended">
                    Based on surging fuel prices across the globe, AIRBOOK ONE is monitoring
                    market prices for jet fuel, as we are committed to making these possible
                    additional surcharges as limited as possible.
                </p>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
      {history.location.pathname != "/book-flight" && (
        <div className="reward-panel">
          <div className="checkbox-panel">
            <CheckBox
              disabled={!reward}
              checked={false}
              onClick={(checked) => {
                handleRewards(checked);
              }}
            />
          </div>
          <div className="info-panel">
            <p className="title">Apply AB1 Rewards</p>
            <p className="sub-title">Balance ${balance?.rewardBalance || 0}</p>
          </div>
        </div>
      )}
    </div>
  );
}

CostSummary.propTypes = {
  priceBreakup: Proptypes.object,
  book: Proptypes.bool,
};

function mapStateToProps({ aircraft }) {
  return {
    selectedAircraft: aircraft.selectedAircraft,
    balance: aircraft.balance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAddOns: (data) => dispatch({ type: UPDATE_ADDONS, payload: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CostSummary);
