import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FaPlus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import addDays from "date-fns/addDays";
import Circle from "../../assets/icons/search-flight-icons/circle.png";
import AutoComplete from "../../components/AutoComplete";
import { getSuggestionTile, removeItemFromArray } from "./helper";
import ArrowRight from "../../assets/icons/search-flight-icons/arrow-right.png";
import Button from "../../components/Button";
import Location from "../../assets/icons/search-flight-icons/location.png";
import { GET_UN_AUTH } from "../../utils/HTTP.utils";
import { API_SEARCH_AIRPORT } from "../../middleware/constants/url.constants";
import moment from "moment";

function MultiCityForm({ minDate, setData, existing, setIsEdit }) {
  const [stops, setStops] = useState([
    { value: {}, suggestions: [], loading: true, inputValue: "", date: "", restriction: null },
  ]);
  const [firstStop, setFirstStop] = useState({
    value: {},
    suggestions: [],
    inputValue: "",
    loading: true,
    date: "",
    restriction: null
  });
  const [lastStop, setLastStop] = useState({
    value: {},
    suggestions: [],
    loading: true,
    inputValue: "",
    restriction: null
  });
  let timeRegex = /([0-9][0-9]):([0-9][0-9]):([0-9][0-9])/;
  let dateRegex = /([A-Z,a-z]){3,3} ([0-9][0-9]) [0-9]{4,4}/;
  let [time, setTime] = useState([0, 0, 0, 0]);
  let [day, setDay] = useState(true);


  /*
  @description: Handles Prefill data when user select Edit date
 */
  useEffect(() => {
    if (Object.keys(existing || {}).length > 0) {
      if(existing.firstStop.date){
        existing.firstStop.date = new Date(existing.firstStop.date)
      }
      if(existing.lastStop.date){
        existing.lastStop.date = new Date(existing.lastStop.date)
      }
      existing.stops = existing.stops.map((item) => {
        if(item.date){
          item.date = new Date(item.date)
        }
        return item;
      })
      setFirstStop({ ...firstStop, ...existing.firstStop });
      setStops([...existing.stops]);
      setLastStop({ ...lastStop, ...existing.lastStop });
    }
  }, []);

  useEffect(() => {
    setData({ firstStop, stops, lastStop });
    setIsEdit(true);
  }, [firstStop, stops, lastStop]);

  const fetchAndSetAirports = (query, setter) => {
    GET_UN_AUTH(API_SEARCH_AIRPORT, { searchStr: query }).then((data) =>
      setter(data)
    );
  };
  const onFirstStopChange = ({ target }) => {
    if(target.value.trim()===""){
      setFirstStop({
        value: {},
        suggestions: [],
        inputValue: "",
        restriction: null,
        loading: true,
      })
    }else{
      setFirstStop({ ...firstStop, inputValue: target.value });
    }
    if (target.value.length > 2) {
      setFirstStop({ ...firstStop, inputValue: target.value, loading: true });
      fetchAndSetAirports(target.value, (data) =>
        setFirstStop({
          ...firstStop,
          suggestions: data,
          loading: false,
          inputValue: target.value,
        })
      );
    }
  };
  const onLastStopChange = ({ target }) => {
    if(target.value.trim()===""){
      setLastStop({
        value: {},
        suggestions: [],
        inputValue: "",
        restriction: null,
        loading: true,
      })
    }else{
      setLastStop({ ...lastStop, inputValue: target.value });
    }
    if (target.value.length > 2) {
      setLastStop({ ...lastStop, inputValue: target.value, loading: true });
      fetchAndSetAirports(target.value, (data) =>
        setLastStop({
          ...lastStop,
          loading: false,
          suggestions: data,
          inputValue: target.value,
        })
      );
    }
  };

  const onDynamicChange = (index) => ({ target }) => {
    if(target.value.trim()===""){
      stops[index] = { value: {}, suggestions: [], loading: true, inputValue: "", date: "", restriction: null }
      setStops([...stops]);
    }else{
      stops[index].inputValue = target.value;
      setStops([...stops]);
    }
      if (target.value.length > 2) {
        stops[index].loading = true;
        fetchAndSetAirports(target.value, (data) => {
          stops[index].loading = false;
          stops[index].suggestions = data;
          stops[index].inputValue = target.value;
          setStops([...stops]);
        });
      }
    };
  const getDepartFiled = (value) => {
    return (
      <div className="input-group">
        <div>
          <img src={Circle} alt="depart from" />
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={value}
          placeholder="Departure"
          onChange={onFirstStopChange}
        />
      </div>
    );
  };
  const getDynamicFilled = (index) => {
    return (
      <div className="input-group">
        <div>
          <img src={Circle} alt="depart from" />
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={stops[index].inputValue}
          placeholder="Departure"
          onChange={onDynamicChange(index)}
        />
      </div>
    );
  };
  const getArrivalFiled = () => {
    return (
      <div className="input-group">
        <div>
          <img src={Location} alt="Arrive at" />
        </div>
        <input
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={lastStop.inputValue}
          placeholder="Destination"
          onChange={onLastStopChange}
        />
      </div>
    );
  };
  return (
    <div className={"MultiCityForm"}>
      <div className="search-input-grp input-form-fill">
        <AutoComplete
          input={getDepartFiled(firstStop.inputValue)}
          suggestions={firstStop.suggestions}
          loading={firstStop.loading}
          value={firstStop.inputValue}
          restriction={firstStop.restriction}
          onSelect={(a) => {
            setFirstStop({
              ...firstStop,
              value: a,
              suggestions: [],
              inputValue: a.airportName,
              restriction: a.restriction
            });
          }}
          getSuggestionTile={getSuggestionTile}
        />
      </div>
      <div className="search-input-grp-date input-form-fill">
        <div>
          <img src={ArrowRight} alt="Depart Date" />
        </div>
        <DatePicker
          selected={firstStop.date}
          minDate={addDays(new Date(), 2)}
          openToDate={addDays(new Date(), 2)}
          showTimeSelect
          placeholderText="Depart Date"
          dateFormat="MMMM d, yyyy h:mm aa"
          onChange={(date) => {
            if (moment(date).isAfter(minDate)) {
              setFirstStop({ ...firstStop, date });
            }
          }}
        />
      </div>
      {stops.map((a, i) => (
        <>
          <div
            className="search-input-grp input-form-fill"
            key={`${i}-autofill`}
          >
            <AutoComplete
              input={getDynamicFilled(i)}
              suggestions={stops[i].suggestions}
              value={stops[i].inputValue}
              loading={stops[i].loading}
              restriction={stops[i].restriction}
              onSelect={(a) => {
                stops[i].inputValue = a.airportName;
                stops[i].suggestions = [];
                stops[i].value = a;
                stops[i].restriction = a.restriction;
                setStops([...stops]);
              }}
              getSuggestionTile={getSuggestionTile}
            />
          </div>
          <div
            className="search-input-grp-date input-form-fill"
            key={`${i}-date`}
          >
            <div>
              <img src={ArrowRight} alt="Depart Date" />
            </div>
            <DatePicker
              selected={a.date}
              disabled={firstStop.date === ""}
              minDate={firstStop.date}
              openToDate={firstStop.date}
              placeholderText={"Depart Date"}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              onChange={(date) => {
                if (moment(date).isAfter(minDate)) {
                  stops[i].date = date;
                  setStops([...stops]);
                }
              }}
            />
            {i > 0 && (
              <div className="clear-action">
                <IoClose
                  color={"red"}
                  onClick={() => {
                    let removed = removeItemFromArray(stops, i);
                    setStops(removed);
                  }}
                />
              </div>
            )}
          </div>
        </>
      ))}
      <div className="search-input-grp input-form-fill">
        <AutoComplete
          input={getArrivalFiled()}
          suggestions={lastStop.suggestions}
          value={lastStop.inputValue}
          loading={lastStop.loading}
          restriction={lastStop.restriction}
          onSelect={(a) => {
            setLastStop({
              ...lastStop,
              value: a,
              suggestions: [],
              inputValue: a.airportName,
              restriction: a.restriction
            });
          }}
          getSuggestionTile={getSuggestionTile}
        />
      </div>
      <div className="add-leg-container">
        <Button
          size={"small"}
          label={"Add Leg"}
          fullWidth={false}
          onClick={() => {
            setStops([
              ...stops,
              { value: {}, suggestions: [], inputValue: "", date: "" },
            ]);
          }}
          icon={<FaPlus />}
          type={"blue-link"}
        />
      </div>
    </div>
  );
}

export default MultiCityForm;
