import React, { useState, useEffect } from "react";
import "./HomePage.scss";
import Modal from "../../components/Modal";
import BenefitSummary from "../../containers/BenefitSummary/BenefitSummary";
import CreateAccount from "../../containers/Login/CreateAccount";
import CreatePassword from "../../containers/Login/CreatePassword";
import Login from "../../containers/Login/Login";
import "../../containers/PopularTrips/PopularTrips.scss";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import { Auth } from "aws-amplify";

const GeneralModal = (props) => {

    let t = 15; // time until popup

  const [modalClose, setModalClose] = useState(false);
  const [fields, setFields] = useState({});
  const [modal, setModal] = useState(props.modaltype || "");
  const [accountModal, setAccountModal] = useState(false);
  const [userData, setUserData] = useState("")
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  let [offerModal, setOfferModal] = useState(false);
  let [peekModal, setPeekModal] = useState(false);

  const modalHandler = (data, props) => {
    setModal(data);
    setFields(props);
    setModalClose(true);
  };

  const close = () => {


    setModalClose(false);
    props.modalLoginClose()

    const socialLogin = localStorage.getItem("socialLogin") || "no";
    if(socialLogin === "yes") {
    console.log(socialLogin)
    localStorage.removeItem("socialLogin")
    setIsAuthenticated(false)

      Auth.signOut({})
      .then(() => {
        // Handle any additional logic after successful sign-out
        console.log('User signed out successfully');
        return false
      })
      .catch((error) => {
        // Handle sign-out errors
        console.error('Error signing out:', error);
      });
    }

  };


    useEffect(() => {
        if(modal !== ''){
            setModalClose(true);
        }
    },[modal]);

    useEffect(() => {
      console.log("Gernal  modal")
        getIsUaAuthentication().then(({ status, createAccount, userData}) => {
        setIsAuthenticated(status);
        if (!status) {
            if (createAccount){
                setAccountModal(true);
                setUserData(userData);
                setOfferModal(false);
            }else{
                setTimeout(() => {
                    setOfferModal(true);
                }, t * 1000);
            }
        }
        });
    },[]);

  useEffect(() => {
    if(accountModal===true){
      modalHandler("createAccount");
      setModalClose(true);
     
    }
  },[accountModal]);

  return (
          <div>
            {modal === "benefit" ? (
              <div className="join-popup">
                <Modal
                  benefit={true}
                  close={() => setModalClose(false)}
                  show={modalClose}
                >
                  <BenefitSummary modalHandler={modalHandler} />
                </Modal>
              </div>
            ) : (
              <Modal
                createLogin={true}
                close={() =>close()}
                show={modalClose}
                preventClose={true}
              >
                {modal === "login" ? (
                  <Login modalHandler={modalHandler} />
                ) :( modal === "createAccount") ? (
                  <CreateAccount modalHandler={modalHandler} userData={null}/>
                )  :( accountModal) ? (
                  <CreateAccount modalHandler={modalHandler} userData={userData}/>
                ): modal === "createPassword" ? (
                  <CreatePassword
                    modalHandler={modalHandler}
                    fieldValue={fields}
                    close={close}
                  />
                ) : null}
              </Modal>
            )}
          </div>      
  );
};

export default GeneralModal;
