import React, { useState, useEffect, useRef } from "react";
import SearchFlightForm from "../HomePage/SearchFlightForm";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "./PopularTripBooking.scss";
import home_video from "../../assets/home_video/popular_video.mov";
// Benefit modal
import GeneralModal from "../../containers/HomePage/Modal";
import { getIsUaAuthentication } from "../../hooks/useAuthentication";
import Modal from "../../components/Modal";
import BenefitSummary from "../../containers/BenefitSummary/BenefitSummary";
import CreateAccount from "../../containers/Login/CreateAccount";
import CreatePassword from "../../containers/Login/CreatePassword";
import Login from "../../containers/Login/Login";
import "../HomePage/HomePage.scss";
import ArrowDown from '../../assets/icons/arrow-down.png';
import { Auth } from "aws-amplify";

const PopularTripBooking = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;

    const loopVideo = () => {
      // Check if the current time of the video is greater than or equal to 19 seconds
      if (video.currentTime >= 17) {
        // Reset the current time to 0 to loop the video
        video.currentTime = 0;
      }
    };

    // Add an event listener to the 'timeupdate' event of the video
    video.addEventListener("timeupdate", loopVideo);

    // Clean up the event listener when the component unmounts
    return () => {
      video.removeEventListener("timeupdate", loopVideo);
    };
  }, []);
  //Benefit modal

  let t = 3; // time until popup
  let [offerModal, setOfferModal] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [modalClose, setModalClose] = useState(false);
  const [fields, setFields] = useState({});
  const [modal, setModal] = useState("");
  const [accountModal, setAccountModal] = useState(false);
  const [userData, setUserData] = useState("");
  const modalHandler = (data, props) => {
    setModal(data);
    setFields(props);
    setModalClose(true);
  };

  const close = () => {
    setModalClose(false);
    const socialLogin = localStorage.getItem("socialLogin") || "no";
    if(socialLogin === "yes") {
      setIsAuthenticated(false)
      localStorage.removeItem("socialLogin")
      Auth.signOut({})
      .then(() => {
        // Handle any additional logic after successful sign-out
        console.log('User signed out successfully');
       
        return false
      })
      .catch((error) => {
        // Handle sign-out errors
        console.error('Error signing out:', error);
      });
    }
  };

  useEffect(() => {
    getIsUaAuthentication().then(({ status, createAccount, userData }) => {
      setIsAuthenticated(status);
      if (!status) {
        if (createAccount) {
          setAccountModal(true);
          setUserData(userData);
          setOfferModal(false);
        } else {
          setTimeout(() => {
            setOfferModal(true);
          }, t * 1000);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (accountModal === true) {
      modalHandler("createAccount");
      setModalClose(true);
      console.log("modal", modal);
    }
  }, [accountModal]);
    return(
      <div className='popular-trip-main'>
        <div className="PopularTripBooking">
            <div className="search">
        <video className="videoTag1" autoPlay loop muted ref={videoRef}>
         <source src={home_video} type="video/mp4" />
        </video>
        <div>
            {modal === "benefit" ? (
              <div className="join-popup">
                <Modal
                  benefit={true}
                  close={() => setModalClose(false)}
                  show={modalClose}
                >
                  <BenefitSummary modalHandler={modalHandler} />
                </Modal>
              </div>
            ) : (
              <Modal
                createLogin={true}
                close={() => close()}
                show={modalClose}
                preventClose={true}

              >
                {modal === "login" ? (
                  <Login 
                  modalHandler={modalHandler}
                  history={history}
                  />
                ) :( modal === "createAccount") ? (
                  <CreateAccount modalHandler={modalHandler} userData={null}/>
                )  :( accountModal) ? (
                  <CreateAccount modalHandler={modalHandler} userData={userData}/>
                ): modal === "createPassword" ? (
                  <CreatePassword
                    modalHandler={modalHandler}
                    fieldValue={fields}
                    close={close}
                  />
                ) : null}
              </Modal>
            )}
          </div>
        <div className="left-section1">
       <p> Private jets can fly to a wide range of destinations, and popular routes can vary based on factors such as business travel, leisure destinations, and events. </p>
       <p>    Based on our customer data, here are today’s popular routes: </p>
       
        </div>
        <SearchFlightForm history={history} loading={setLoader} modalHandler={modalHandler}/>
            
            <LoadingOverlay active={loader} spinner text='Loading...'/>
          
        </div>
        <div className="Popular-Routes-container">
          <div className="Popular-Routes-new">
            <h4 >More Popular Routes</h4>
            <img 
                        src={ArrowDown}
                       // onClick={() => {
                          onClick={() => history.push("/popular-routes")}
                       // }}
                        alt='scroll down'
                    />
          </div>
        </div>
      
        </div>
        </div>
    )
}

export default PopularTripBooking;