import React, {useEffect, useState} from 'react';
import CheckBox from "../../components/CheckBox/CheckBox";
import LoadingOverlay from "react-loading-overlay";
import Button from "../../components/Button";
import {getIsUaAuthentication} from "../../hooks/useAuthentication";
import {GET_AUTH, POST_AUTH} from "../../utils/HTTP.utils";
import {
  API_GET_NOTIFICATIONS_PREFERENCES,
  API_POST_NOTIFICATIONS_PREFERENCES
} from "../../middleware/constants/url.constants";
import { AiTwotoneFileUnknown } from 'react-icons/ai';

function Notification({}) {
  const [state, setState] = useState({email: false, notifications: false, sms: false});
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([]);
  const onCheckChange = key => value => {
    setState({...state, [key]: value})
  }
  const fetchPreferences = async () => {
    let {token} = await getIsUaAuthentication();
    let data = await GET_AUTH(API_GET_NOTIFICATIONS_PREFERENCES, token, {});
    setState({ ...state, email: data.enableEmail, notifications: data.enableNotification })
    setLoader(false)
  }
  useEffect(() => {
    fetchPreferences();
  },[]);


  const onSubmit = async () => {
    try {
      let {token} = await getIsUaAuthentication();
      await POST_AUTH(`${API_POST_NOTIFICATIONS_PREFERENCES}?notification=${state.notifications}&email=${state.email}&sms=${state.sms}`, {}, token)
    } catch {
      console.error("Error While Doing!")
    }
  }
  if (!loader) {
    return (
      <div className="Notification">
        <div className="preference-container">
          <div className="preference-tile">
            <CheckBox
              color={"blue"}
              checked={state.email}
              onClick={onCheckChange("email")}
            />{" "}
            <p className="label">Email</p>
          </div>
          <div className="preference-tile">
            <CheckBox
              color={"blue"}
              checked={state.notifications}
              onClick={onCheckChange("notifications")}
              />{" "}
              
            <p className="label">App Notifications</p>
          </div>
          <div className="disclaimer">
            <p>
              Even if you opt out of marketing communications, you will still
              receive transactional emails from us, such as order receipts,
              add-on reminders and itinerary related updates.
            </p>
          </div>
          <Button label={"Save"} type={"yellow"} onClick={onSubmit} />
        </div>
        {data.map((a) => (
          <div className="notification-history-tile">
            <div className="info">
              <p className="key">12-Nov-2021</p>
              <p className="value">GOT NOTIFICATION</p>
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <LoadingOverlay active={loader} spinner text="Loading your content..." />
    );
  }
}

export default Notification;
